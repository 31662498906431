import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  ConfigProvider as OkeeDesignConfigProvider,
  ErrorBoundary,
} from '@okee-uikit/react';
import {
  Questionnaire,
  Widgets,
  QuestionAnswer,
  ShowQuestionnaire,
  SubmitAnswerReq,
  EDefaultTriggerKey,
} from '@byted-feelgood/components';
import { debounce, merge } from 'lodash-es';
import {
  ClosePosEnum,
  IEmbeddedQuestionnaireOptions,
  IEmbeddedQuestionnaireRef,
} from '../../types';
import {
  debounceSendSlardar,
  ECustomEvent,
  sendSlardarEvent,
} from '@/libs/slardar';
import { getDevice } from '@/utils';

interface IEmbeddedQuestionnaireProps {
  isRTL: boolean;
  isCustomEl: boolean;
  options?: IEmbeddedQuestionnaireOptions;
  sign?: Record<string, string>;
  showQuestionnaire: ShowQuestionnaire;
  postEvent: (
    triggerKey: EDefaultTriggerKey | string,
    param?: {
      sign?: { [key: string]: string };
      questionnaireID?: string;
    },
  ) => Promise<any>;
  postAnswer: (
    params: Omit<
      SubmitAnswerReq,
      'eventTime' | 'accessUserInfo' | 'platID' | 'submitID'
    >,
  ) => Promise<any>;
  unmount: () => void;
}
/**
 * 嵌入式问卷
 */
const EmbeddedQuestionnaire = forwardRef<
  IEmbeddedQuestionnaireRef,
  IEmbeddedQuestionnaireProps
>((props, ref) => {
  const {
    isRTL = false,
    isCustomEl = false,
    sign,
    options,
    showQuestionnaire,
    postEvent,
    postAnswer,
    unmount,
  } = props;
  const { questionnaireID, surveyID, surveyVersion } = showQuestionnaire;
  /**
   * 是否 Mounted返回false
   */
  const isMountedFalseRef = useRef(false);
  /**
   * 是否 问卷组件挂了
   */
  const isQuestionnaireErrorRef = useRef(false);
  /**
   * 是否 已经开始答题了
   */
  const hasAnswerRef = useRef(false);
  /**
   * 是否 已经提交过（成功或失败）
   */
  const hasSubmitRef = useRef(false);
  /**
   * 是否 已经上报了拒答
   */
  const hasPostRejectRef = useRef(false);
  /**
   * 问卷组件挂载的时间
   */
  const questionnaireMountedTimestampRef = useRef(0);

  const sendEvent = useCallback(
    (
      event: string,
      data?: Parameters<typeof sendSlardarEvent>[1],
      debounce?: boolean,
    ) => {
      const sendEventFunc = debounce ? debounceSendSlardar : sendSlardarEvent;
      sendEventFunc(event, {
        metrics: data?.metrics,
        categories: {
          questionnaireID,
          surveyID,
          surveyVersion,
          ...data?.categories,
        },
      });
    },
    [questionnaireID, surveyID, surveyVersion],
  );
  const reportEvent = useCallback<typeof postEvent>(
    (event, param) => {
      return postEvent(event, {
        questionnaireID,
        sign,
        ...param,
      });
    },
    [questionnaireID, sign],
  );
  /**
   * 上报拒绝答
   */
  const postReject = debounce(
    useCallback(() => {
      reportEvent(EDefaultTriggerKey.Reject).then(() => {
        hasPostRejectRef.current = true;
      });
    }, [reportEvent]),
    250,
  );
  /**
   * 处理点击关闭按钮
   */
  const handleClose = debounce(
    useCallback(
      (closePos: ClosePosEnum, trueClose?: boolean) => {
        options?.onClose?.(closePos, trueClose);
        if (!trueClose) {
          reportEvent(EDefaultTriggerKey.CloseClick, {
            questionnaireID,
          });
          sendEvent(ECustomEvent.QuestionnaireSurveyHidden);
          return;
        }
        if (!hasPostRejectRef.current) {
          postReject();
        }
        sendEvent(ECustomEvent.QuestionnaireClose);
      },
      [options, postReject, sendEvent],
    ),
    250,
  );
  /**
   * 处理填写答案
   */
  const handleAnswerChange = useCallback(
    field => {
      if (!hasAnswerRef.current) {
        reportEvent(EDefaultTriggerKey.Answer).then(() => {
          hasAnswerRef.current = true;
        });
      }
      sendEvent(
        ECustomEvent.QuestionnaireQuestionAnswerChange,
        {
          categories: {
            questionKey: field?.path?.entire,
            questionAnswer: field?.value,
            questionIndex: field?.data?.questionIndex,
          },
        },
        true,
      );
    },
    [reportEvent, sendEvent],
  );
  /**
   * 处理提交
   */
  const handleSubmit = useCallback(
    async (answerData: QuestionAnswer[]): Promise<{ submitID?: string }> => {
      return postAnswer({
        questionnaireID,
        reportParam: {
          sign,
        } as any,
        submitCost:
          new Date().getTime() - questionnaireMountedTimestampRef.current,
        answerData,
      })
        .then(result => {
          sendEvent(ECustomEvent.QuestionnaireSubmitSuccess, {
            categories: {
              submitID: result?.submitID,
            },
          });
          return { submitID: result?.submitID };
        })
        .catch(error => {
          sendEvent(ECustomEvent.QuestionnaireSubmitFailed);
          throw error;
        });
    },
    [postAnswer, questionnaireID, sign, sendEvent],
  );
  /**
   * 问卷内容展示
   */
  const handleSurveyShow = useCallback(() => {
    if (!isMountedFalseRef.current) {
      questionnaireMountedTimestampRef.current = new Date().getTime();
      reportEvent(EDefaultTriggerKey.Show, {
        questionnaireID,
      });
    }
    options?.onSurveyShow?.();
  }, [options, questionnaireID, reportEvent]);

  useEffect(() => {
    if (!isQuestionnaireErrorRef.current) {
      sendEvent(ECustomEvent.QuestionnaireMounted);
      (async () => {
        if (
          options?.onMounted &&
          (await options?.onMounted?.(showQuestionnaire)) === false
        ) {
          //  onMounted 返回 false，阻止上报 show 事件
          isMountedFalseRef.current = true;
        }
      })();
    }

    return () => {
      if (!isQuestionnaireErrorRef.current) {
        sendEvent(ECustomEvent.QuestionnaireUnmounted);
        const hasShow = questionnaireMountedTimestampRef.current > 0;
        if (hasShow && !hasSubmitRef.current && !hasPostRejectRef.current) {
          // 组件卸载时，未提交成功 且 未上报过拒答， 则上报 拒答
          postReject();
        }
        options?.onUnmounted?.();
      }
    };
  }, [options, reportEvent, postReject, questionnaireID, sendEvent]);
  /**
   * 暴露到外部的接口
   */
  useImperativeHandle(
    ref,
    () => ({
      setQuestionnaireStatusDefault: () => {
        questionnaireMountedTimestampRef.current = 0;
        hasAnswerRef.current = false;
        hasSubmitRef.current = false;
        hasPostRejectRef.current = false;
      },
    }),
    [reportEvent, questionnaireID, sendEvent],
  );

  const theme = useMemo(() => {
    let finTheme = {};
    try {
      finTheme = merge(
        {},
        JSON.parse(showQuestionnaire.theme || '{}'),
        options?.theme,
      );
    } catch (e) {
      console.error('getThemeFail');
    }
    return finTheme;
  }, [options?.theme, showQuestionnaire.theme]);

  const SurveyComponent = useMemo(() => {
    return isCustomEl ? Questionnaire : Widgets;
  }, [isCustomEl]);
  return (
    <OkeeDesignConfigProvider prefixCls={`feelgood-deliverer-okee`}>
      <ErrorBoundary
        onError={(error, stack) => {
          isQuestionnaireErrorRef.current = true;
          sendSlardarEvent(ECustomEvent.QuestionnaireError, {
            categories: {
              name: error?.name,
              cause: error?.cause,
              message: error?.message,
              stack,
            },
          });
          throw error;
        }}
      >
        <SurveyComponent
          isRTL={isRTL}
          questionnaire={showQuestionnaire.showConfig}
          entranceConfig={showQuestionnaire.entranceConfig}
          isAutoPopup={showQuestionnaire.isAutoPopup}
          defaultTextMap={showQuestionnaire.defaultTranslation}
          onQuestionAnswerChange={handleAnswerChange}
          onEntryShow={() => {
            reportEvent(EDefaultTriggerKey.EntryShow, {
              questionnaireID,
            });
            sendSlardarEvent(ECustomEvent.QuestionnaireEntryShow);
          }}
          onEntryClick={state => {
            if (hasSubmitRef.current) {
              return hasSubmitRef.current;
            }
            if (!state) {
              reportEvent(EDefaultTriggerKey.EntryClick, {
                questionnaireID,
              });
              sendSlardarEvent(ECustomEvent.QuestionnaireEntryClick);
            } else {
              reportEvent(EDefaultTriggerKey.CloseClick, {
                questionnaireID,
              });
              sendEvent(ECustomEvent.QuestionnaireSurveyHidden);
            }
            return hasSubmitRef.current;
          }}
          onEnd={() => {
            unmount();
          }}
          {...{
            ...options,
            theme,
            device: options?.device || getDevice(),
            onClose: handleClose,
            onSubmit: handleSubmit,
            onSurveyShow: handleSurveyShow,
            onSubmitStart(form) {
              sendEvent(ECustomEvent.QuestionnaireSubmit, {
                categories: {
                  values: form?.values,
                },
              });
              options?.onSubmitStart?.(form?.values);
            },
            onSubmitFailed() {
              hasSubmitRef.current = true;
              options?.onSubmitFailed?.();
            },
            onSubmitSuccess() {
              hasSubmitRef.current = true;
              options?.onSubmitSuccess?.();
            },
          }}
        />
      </ErrorBoundary>
    </OkeeDesignConfigProvider>
  );
});
export default EmbeddedQuestionnaire;
