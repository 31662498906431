import RcTextarea, { TextAreaProps } from 'rc-textarea';
import classNames from 'classnames';
import ComponentStore from '../../store';
import styles from './style.module.less';

const CommonText: React.FC<
  TextAreaProps & { invalid?: boolean; customClassName?: string }
> = prop => {
  const { invalid, placeholder, customClassName, ...rest } = prop;
  const { defaultText, questionTheme } = ComponentStore.useContext();
  return (
    <>
      <RcTextarea
        className={classNames(
          styles['feelgood-common-text'],
          invalid && styles['feelgood-common-text-invalid'],
          'feelgood_question-common-input',
          customClassName,
        )}
        {...rest}
        style={{ ...questionTheme?.textarea }}
        autoSize={true}
        maxLength={1000}
        onPressEnter={e => {
          // 取消默认事件，不支持回车换行，保持单行
          e.preventDefault();
        }}
        placeholder={placeholder || defaultText?.please_input}
      />
      {invalid && (
        <div className={classNames(styles['feelgood-common-text-error'])}>
          {defaultText?.is_require}
        </div>
      )}
    </>
  );
};
export default CommonText;
