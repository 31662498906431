import { Down, FeelgoodOne } from '@icon-park/react';
import classNames from 'classnames';
import styles from './style.module.less';
import '../../feelgood.less';

interface EntryType {
  entryUrl?: string;
  onClick: () => void;
}

const Entry: React.FC<EntryType & { containerShow: boolean }> = props => {
  const { entryUrl, onClick, containerShow } = props;
  return (
    <div
      className={classNames(
        styles.questionnaire__entry,
        styles.entry,
        'feelgood-widgets_entry',
      )}
      style={{
        backgroundColor: entryUrl ? 'transparent' : '',
        boxShadow: entryUrl ? 'unset' : '',
      }}
      onClick={() => {
        onClick?.();
      }}
    >
      {entryUrl ? (
        <img
          src={entryUrl}
          className={classNames(styles.customImg, 'feelgood-widgets_entry-img')}
          alt=""
          onError={() => {
            console.warn('img error');
          }}
        />
      ) : (
        <>
          {!containerShow ? (
            <FeelgoodOne theme="outline" size="24" fill="#ffffff" />
          ) : (
            <Down theme="outline" size="24" fill="#ffffff" />
          )}
        </>
      )}
    </div>
  );
};

export default Entry;
