import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { axiosResponseToSlardar } from '../../libs/slardar';
import { IAuthToken, IFeelgoodConfig } from '../../types';

export interface IFeelgoodResponse<T = any> {
  code: number;
  data: T;
  message: string;
}
/**
 * token 过期的错误码
 */
const tokenExpiredCode = 488;

/**
 * 请求拦截器 id
 */
let requestInterceptor: number;

/**
 * 响应拦截器 id
 */
let responseInterceptor: number;

/**
 * 重试累计
 */
let reTryCount = 0;

function getAxiosInstance() {
  reTryCount = 0;
  return axios.create({
    timeout: 5 * 1000,
    withCredentials: true,
  });
}

function getBaseURL({ host, devUrl }: IFeelgoodConfig) {
  if (devUrl) {
    return devUrl;
  }
  return `https://${host}/survey`;
}

// 请求拦截
const requestInterceptorFunc = (
  config: IFeelgoodConfig,
  instance: AxiosInstance,
) => {
  const { authToken, getAuthToken } = config;
  if (typeof requestInterceptor === 'number') {
    // 避免request拦截器被多次调用
    instance.defaults.headers.common.Authorization = '';
    instance.interceptors.request.eject(requestInterceptor);
  }
  requestInterceptor = instance.interceptors.request.use(
    async requestConfig => {
      if (!(requestConfig.headers?.common as any)?.Authorization) {
        try {
          // 请求头不存在鉴权信息，尝试请求一次
          const { token, tokenType } =
            authToken || (await getAuthToken?.()) || {};
          const Authorization =
            token && tokenType ? `${tokenType} ${token}` : '';
          instance.defaults.headers.common.Authorization = Authorization;
          requestConfig.headers!.Authorization = Authorization;
        } catch (error) {
          console.warn('failed to get token before request', error);
        }
      }
      return requestConfig;
    },
  );
};

// 响应拦截
const responseInterceptorFunc = (
  getAuthToken: ((options?: any) => Promise<IAuthToken>) | undefined,
  instance: AxiosInstance,
) => {
  if (typeof responseInterceptor === 'number') {
    // 避免response拦截器多次调用
    instance.interceptors.response.eject(responseInterceptor);
  }
  responseInterceptor = instance.interceptors.response.use(
    async (response: AxiosResponse<IFeelgoodResponse>) => {
      axiosResponseToSlardar(response);
      const { data, config } = response;
      if (data.code === 0) {
        return data;
      } else if (
        data.code === tokenExpiredCode &&
        getAuthToken &&
        reTryCount < 5
      ) {
        // 说明token过期了,刷新token
        const { token, tokenType } = await getAuthToken();
        reTryCount += 1;
        instance.defaults.headers.common.Authorization =
          token && tokenType ? `${tokenType} ${token}` : '';
        return instance({
          ...config,
          headers: {
            ...config.headers,
            // 原本的 config.headers 里有之前的 Authorization，必须覆盖掉，否则无限循环
            Authorization: instance.defaults.headers.common.Authorization,
          },
        });
      } else {
        throw data;
      }
    },
    (axiosError: AxiosError<IFeelgoodResponse>) => {
      axiosResponseToSlardar(axiosError);
      throw axiosError;
    },
  );
};
/**
 * axios 请求配置
 * @param param0
 */
export const setAxiosConfig = (config: IFeelgoodConfig) => {
  const instance = getAxiosInstance();
  instance.defaults.baseURL = getBaseURL(config);
  const { getAuthToken } = config;
  requestInterceptorFunc(config, instance);
  responseInterceptorFunc(getAuthToken, instance);
  return instance;
};

export * from './token';
