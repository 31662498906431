import {
  Device,
  QuestionOrigin,
  QuestionAnswer,
  SubmitAnswerResp,
} from '@byted-feelgood/components';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const postAnswer = async (
  instance: AxiosInstance,
  params: {
    platID: string;
    questionnaireID: string;
    eventTime: string;
    reportParam: {
      /** 用户自定义标识 */
      sign: { [key: string]: string };
      device: Device;
      /** ISO 639-1 标准 */
      language: string;
      /** 问卷相关需要上报 */
      questionOrigin?: QuestionOrigin;
      deviceID: string;
    };
    /** 毫秒 */
    submitCost: number;
    answerData: Array<QuestionAnswer>;
  },
  config?: AxiosRequestConfig,
): Promise<SubmitAnswerResp> => {
  const { data } = await Promise.race([
    instance.post('/api/feelgood/v1/answer', params, config),
    new Promise<AxiosResponse>((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('timeout'));
      }, 5000);
    }),
  ]);
  return data;
};
