import classNames from 'classnames';
import {
  ClosePosEnum,
  IQuestionnaireProps,
} from '../questionnaire/questionnaire';
import styles from './style.module.less';
import '../../feelgood.less';

const Mask: React.FC<IQuestionnaireProps> = props => {
  const { style = {}, entranceConfig, onClose } = props;
  return (
    <div
      className={classNames(
        styles.questionnaire__mask,
        'feelgood-widgets_mask',
      )}
      style={{
        zIndex: style?.zIndexMask,
        backgroundColor: style?.MaskColor,
      }}
      onClick={() => {
        onClose?.(ClosePosEnum.Mask, !entranceConfig?.enable);
      }}
    ></div>
  );
};

export default Mask;
