import {
  MultiChoiceOptionConfigData,
  MultiChoiceTagOptionConfigData,
  SingleChoiceOptionConfigData,
} from '@/types/feelgood';

type Option =
  | SingleChoiceOptionConfigData
  | MultiChoiceTagOptionConfigData
  | MultiChoiceOptionConfigData;
export function shuffle(arr: any[], exOption: string[] = []) {
  const positionCache: Map<number, Option> = new Map();
  const tempArr = arr.filter((option: Option, index: number) => {
    if (exOption.includes(option.optionKey)) {
      positionCache.set(index, option);
      return false;
    }
    return true;
  });
  let i = tempArr.length;
  while (--i > 0) {
    const j = Math.floor(Math.random() * i);
    [tempArr[j], tempArr[i]] = [tempArr[i], tempArr[j]];
  }
  for (const [key, value] of positionCache) {
    tempArr.splice(key, 0, value);
  }
  return tempArr;
}
