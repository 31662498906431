import { merge } from 'lodash-es';
import ReactDOM from 'react-dom';
import { createRef } from 'react';
import {
  PlatDetailTrim,
  ShowQuestionnaire,
  QuestionOrigin,
  ReportParam,
  SubmitAnswerReq,
  EDefaultTriggerKey,
  ShowQuestionnaireEntry,
  ShowType,
} from '@byted-feelgood/components';
import { AxiosInstance } from 'axios';
import { isRTL } from '@byted-feelgood/components/src/util';
import {
  initSlardar,
  getSlardarConfig,
  sendSlardarEvent,
  ECustomEvent,
  setSlardarContext,
  sendSlardarLog,
} from './libs/slardar';
import { setAxiosConfig } from './services/deliverer';
import { postEvent } from './services/deliverer/event';
import {
  getDevice,
  isInPage,
  startVisibleListen,
  stopVisibleListen,
} from './utils';
import EmbeddedQuestionnaire from './views/embedded-questionnaire';
import { postAnswer } from './services/deliverer/answer';
import { RouterWatch } from './utils/router-watch';
import {
  IEmbeddedQuestionnaireOptions,
  IEmbeddedQuestionnaireRef,
  IFeelgoodConfig,
  ITriggerEvent,
} from './types';
import EmbeddedEntry from './views/embedded-entry';

interface IPlatformDetail
  extends Omit<PlatDetailTrim, 'signList' | 'secretKey'> {
  signList: string[];
}

export default class Feelgood {
  /**
   * feelgood配置
   */
  public config: IFeelgoodConfig;

  /**
   * 当前展示问卷的实例，包含问卷数据和卸载方法
   */
  public currentActiveSurvey?: IEmbeddedQuestionnaireRef | null;

  private routerWatch?: RouterWatch | null;

  private questionnairesIDToSubmitIDMap: Record<string, string | null> = {};

  /**
   * 从token中解构出来的平台详情
   */
  private platformDetail?: IPlatformDetail;

  private delayTimer?: ReturnType<typeof setTimeout>;

  private axiosInstance: AxiosInstance;

  constructor(config: IFeelgoodConfig) {
    console.info('feelgood version:', VERSION);
    try {
      this.config = {
        autoStartListen: true,
        ...config,
      };
      /**
       * 处理token
       */
      this.makeToken(config);
      const {
        getAuthToken,
        questionnaireData,
        authToken,
        container,
        ...restConfig
      } = this.config;
      this.axiosInstance = setAxiosConfig(this.config);
      initSlardar({
        bid: 'feelgood_deliverer_sdk',
        release: VERSION,
        env: this.config?.devUrl ? 'test' : 'production',
        userId: this.config.platformID,
        plugins: {
          ajax: {
            ignoreUrls: [/^((?!\/feelgood\/).)*$/],
          },
          jsError: true,
          fetch: false,
          performance: false,
          blankScreen: false,
        },
      });
      setSlardarContext({
        ...restConfig,
        isDirectRender: Boolean(questionnaireData),
        container: container?.className || container?.id,
      });
      sendSlardarEvent(ECustomEvent.Initialized);
      document.addEventListener('visibilitychange', this.visibleChange);
      /**
       * 直传数据直接渲染
       */
      if (questionnaireData) {
        this.renderSurveyDirect();
        return;
      }
      /**
       * 开始监听和事件上报
       */
      this.config.autoStartListen && this.startListener();
    } catch (error: any) {
      sendSlardarEvent(ECustomEvent.InitializedFailed, {
        categories: {
          message: error?.message,
          stack: error?.stack,
        },
      });
      console.error(error);
      throw new Error('failed to initialized Feelgood SDK');
    }
  }

  /**
   * 更新问卷全局配置
   * 更新会导致已出现的问卷重新加载和计时重新开始
   */
  public setConfig(config: Partial<IFeelgoodConfig>) {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
    }
    this.config = merge(this.config, config);
    if (this.currentActiveSurvey) {
      this.currentActiveSurvey?.setQuestionnaireStatusDefault?.();
      this.currentActiveSurvey?.unmount?.();
      this.triggerView();
    }
  }

  /**
   * 开始监听路由
   */
  public startListener() {
    sendSlardarEvent(ECustomEvent.StartRouterListener);
    // 开始监听同时触发一次view
    this.triggerView();
    this.routerWatch = new RouterWatch({
      handler: (newURL: string, oldURL: string) => {
        this.triggerView(() => {
          this.delayTimer && clearTimeout(this.delayTimer);
          this.currentActiveSurvey?.unmount?.();
        });
        sendSlardarEvent(ECustomEvent.RouterChange, {
          categories: {
            newURL,
            oldURL,
          },
        });
      },
    });
  }

  /**
   * 停止监听
   */
  public stopListener() {
    sendSlardarEvent(ECustomEvent.StopRouterListener);
    this.routerWatch?.destroy();
    this.routerWatch = null;
  }

  /**
   * 销毁实例
   */
  public destroy() {
    this.routerWatch?.destroy();
    this.routerWatch = null;
    this.delayTimer && clearTimeout(this.delayTimer);
    this.delayTimer = undefined;
    this.currentActiveSurvey?.unmount?.();
    stopVisibleListen();
    document.removeEventListener('visibilitychange', this.visibleChange);
    sendSlardarEvent(ECustomEvent.InstanceDestory);
    feelgoodInstance = null;
  }

  /**
   * 自定义事件触发
   * @param triggerKey
   * @param options
   * @returns questionnaire:ShowQuestionnaire
   * @returns current:IEmbeddedQuestionnaireRef
   */
  public async triggerEvent(
    triggerKey: string,
    options: ITriggerEvent,
  ): Promise<{
    questionnaire?: ShowQuestionnaire | ShowQuestionnaireEntry;
    current?: IEmbeddedQuestionnaireRef | null;
  }> {
    const sign = { ...this.config.sign, ...options?.sign };
    const questionnaireOption = {
      ...this.config.questionnaire,
      ...options?.questionnaire,
    };
    sendSlardarEvent(ECustomEvent.TriggerEvent, {
      categories: {
        triggerKey,
        ...options,
      },
    });
    try {
      const data = await this.postEvent(
        triggerKey,
        sign && {
          sign,
        },
      );
      const {
        questionnaire: showQuestionnaire,
        entry: showQuestionnaireEntry,
        showType,
      } = data || {};
      if (showType === ShowType.Questionnaire && showQuestionnaire) {
        if (options.dryrun) {
          sendSlardarEvent(ECustomEvent.TriggerEventDryrun, {
            categories: {
              showQuestionnaire,
            },
          });
          return { questionnaire: showQuestionnaire };
        } else {
          return this.renderQuestionnaire({
            sign,
            questionnaireOption,
            showQuestionnaire,
            container: options.container || this.config.container,
            showType,
          });
        }
      } else if (showType === ShowType.Entry && showQuestionnaireEntry) {
        if (options.dryrun) {
          sendSlardarEvent(ECustomEvent.TriggerEventDryrun, {
            categories: {
              showQuestionnaire,
            },
          });
          return { questionnaire: showQuestionnaireEntry };
        } else {
          return this.renderQuestionnaire({
            sign,
            questionnaireOption,
            showQuestionnaireEntry,
            showType,
            container: options.container || this.config.container,
          });
        }
      } else {
        throw new Error(
          `no questionnaire match, ${JSON.stringify(data || '{}')}`,
        );
      }
    } catch (error: any) {
      sendSlardarEvent(ECustomEvent.TriggerEventFailed, {
        categories: {
          message: error?.message,
          stack: error?.stack,
          triggerKey,
          ...options,
        },
      });
      throw error;
    }
  }

  private makeToken(config: IFeelgoodConfig) {
    if (!window.atob || typeof window.atob !== 'function') {
      console.error('Not in browser');
      throw new Error('Not in browser');
    }
    if (typeof config.getAuthToken === 'function') {
      this.config.getAuthToken = async () => {
        try {
          sendSlardarEvent(ECustomEvent.AuthGetAuthToken);
          const authToken = await config.getAuthToken?.();
          if (authToken?.token) {
            try {
              this.platformDetail = JSON.parse(
                window.atob(authToken?.token.split('.')[1]),
              )?.platform as IPlatformDetail;
            } catch (error) {
              console.warn('failed to parse token', error);
            }
            sendSlardarEvent(ECustomEvent.AuthGetAuthTokenSuccess, {
              categories: {
                platformDetail: this.platformDetail,
              },
            });
            return authToken;
          } else {
            throw new Error(
              'failed to get auth token: token should be returned',
            );
          }
        } catch (error: any) {
          sendSlardarEvent(ECustomEvent.AuthGetAuthTokenFailed, {
            categories: {
              message: error?.message || error?.msg,
            },
          });
          throw error;
        }
      };
    }
    if (config.authToken) {
      try {
        this.platformDetail = JSON.parse(
          window.atob(config.authToken?.token.split('.')[1]),
        )?.platform as IPlatformDetail;
      } catch (error) {
        console.warn('failed to parse token', error);
      }
    }
  }

  private get defaultReportParam() {
    let finalLanguage = this.config.language;
    if (finalLanguage.includes('_')) {
      finalLanguage = finalLanguage.replace('_', '-');
    }
    if (!/^[a-zA-Z]{2,4}(-[a-zA-Z0-9]{2,4}){0,2}$/.test(finalLanguage)) {
      finalLanguage = 'en';
      sendSlardarLog('warn', 'language error', {
        language: this.config.language,
        finalLanguage,
      });
    }
    return {
      referUrl: this.config.deliverPath
        ? this.config.deliverPath
        : window?.location?.href,
      device: getDevice(),
      questionOrigin: QuestionOrigin.Embedded,
      language: finalLanguage,
      deviceID: getSlardarConfig()?.deviceId || '',
    };
  }

  private async triggerView(cb?: () => void) {
    try {
      sendSlardarEvent(ECustomEvent.TriggerEvent, {
        categories: {
          triggerKey: EDefaultTriggerKey.View,
        },
      });
      const data = await this.postEvent(EDefaultTriggerKey.View);
      const {
        questionnaire: showQuestionnaire,
        entry: showQuestionnaireEntry,
        showType,
      } = data || {};
      const newSurveyId =
        showQuestionnaire?.surveyID || showQuestionnaireEntry?.surveyID;
      if (
        cb &&
        this.currentActiveSurvey?.questionnaireData?.surveyID !== newSurveyId
      ) {
        cb();
      }
      if (showType === ShowType.Questionnaire && showQuestionnaire) {
        this.delayTimer = setTimeout(() => {
          this.renderQuestionnaire({
            showQuestionnaire,
            sign: this.config.sign,
            questionnaireOption: this.config.questionnaire,
            container: this.config.container,
            showType,
          });
          this.delayTimer && clearTimeout(this.delayTimer);
          this.delayTimer = undefined;
        }, Number(showQuestionnaire.delay) * 1000 || 0);
      } else if (showType === ShowType.Entry && showQuestionnaireEntry) {
        this.delayTimer = setTimeout(() => {
          this.renderQuestionnaire({
            showQuestionnaireEntry,
            sign: this.config.sign,
            questionnaireOption: this.config.questionnaire,
            container: this.config.container,
            showType,
          });
          this.delayTimer && clearTimeout(this.delayTimer);
          this.delayTimer = undefined;
        }, Number(showQuestionnaireEntry.delay) * 1000 || 0);
      } else {
        throw new Error(
          `no questionnaire match,${JSON.stringify(data || '{}')}`,
        );
      }
    } catch (error: any) {
      sendSlardarEvent(ECustomEvent.TriggerEventFailed, {
        categories: {
          message: error?.message,
          stack: error?.stack,
          triggerKey: EDefaultTriggerKey.View,
        },
      });
    }
  }

  private renderSurveyDirect() {
    const { sign, questionnaire, questionnaireData, container } = this.config;
    if (
      !questionnaireData?.questionnaireID ||
      !questionnaireData?.submitID ||
      !questionnaireData?.showConfig
    ) {
      sendSlardarEvent(ECustomEvent.TriggerEventDirectFailed, {
        categories: {
          questionnaireData,
        },
      });
      console.warn('questionnaireData error', questionnaireData);
      throw new Error(
        `questionnaireData error,please check questionnaireID,submitID,showConfig`,
      );
    }
    setSlardarContext({
      submitID: questionnaireData?.submitID,
      questionnaireID: questionnaireData?.questionnaireID,
      surveyId: questionnaireData?.surveyID,
    });
    sendSlardarEvent(ECustomEvent.TriggerEventDirect, {
      categories: {
        config: this.config,
        questionnaireData,
      },
    });
    this.questionnairesIDToSubmitIDMap[questionnaireData?.questionnaireID] =
      questionnaireData?.submitID;
    this.renderQuestionnaire({
      sign,
      questionnaireOption: questionnaire,
      showQuestionnaire: questionnaireData,
      container,
    });
  }

  /**
   * 处理问卷渲染
   */
  private renderQuestionnaire({
    sign,
    questionnaireOption,
    showQuestionnaire,
    showQuestionnaireEntry,
    showType = ShowType.Questionnaire,
    container,
  }: {
    sign?: Record<string, string>;
    questionnaireOption?: IEmbeddedQuestionnaireOptions;
    showQuestionnaire?: ShowQuestionnaire;
    showQuestionnaireEntry?: ShowQuestionnaireEntry;
    showType?: ShowType;
    container?: HTMLElement;
  }) {
    // 存在上一个问卷直接返回
    if (this.currentActiveSurvey) {
      sendSlardarEvent(ECustomEvent.TriggerEventAbandonWithExisted, {
        categories: {
          showQuestionnaire,
        },
      });
      return { questionnaire: showQuestionnaire || showQuestionnaireEntry };
    }
    const { questionnaireID } =
      showQuestionnaire || showQuestionnaireEntry || {};
    const elRootId = `feelgood_root_${questionnaireID}`;
    sendSlardarEvent(ECustomEvent.TriggerEventRenderQuestionnaire);
    let el =
      container && isInPage(container as Node)
        ? container
        : document.getElementById(elRootId);
    if (!el) {
      el = document.createElement('div');
      el.id = elRootId;
      document.body.append(el);
    }
    const unmount = async () => {
      sendSlardarEvent(ECustomEvent.QuestionnaireUnmount);
      ReactDOM.unmountComponentAtNode(el as HTMLElement);
      const node = document.getElementById(elRootId);
      if (node?.parentNode) {
        node.parentNode.removeChild(node);
      }
      this.currentActiveSurvey = null;
    };
    const ref = createRef<IEmbeddedQuestionnaireRef>();
    if (showType === ShowType.Questionnaire && showQuestionnaire) {
      ReactDOM.render(
        (
          <EmbeddedQuestionnaire
            unmount={async () => {
              if (
                !questionnaireOption?.onBeforeUnmounted ||
                (await questionnaireOption?.onBeforeUnmounted?.()) !== false
              ) {
                unmount();
              }
            }}
            isRTL={isRTL(this.config.language)}
            isCustomEl={Boolean(container) && isInPage(container as Node)}
            sign={sign}
            postEvent={this.postEvent.bind(this)}
            postAnswer={this.postAnswer.bind(this)}
            options={questionnaireOption}
            showQuestionnaire={showQuestionnaire}
            ref={ref}
          />
        ) as any,
        el,
      );
    }
    if (showType === ShowType.Entry && showQuestionnaireEntry) {
      ReactDOM.render(
        (
          <EmbeddedEntry
            unmount={async () => {
              if (
                !questionnaireOption?.onBeforeUnmounted ||
                (await questionnaireOption?.onBeforeUnmounted?.()) !== false
              ) {
                unmount();
              }
            }}
            host={this.config.host}
            language={this.config.language}
            isRTL={isRTL(this.config.language)}
            sign={sign}
            postEvent={this.postEvent.bind(this)}
            options={questionnaireOption}
            showQuestionnaireEntry={showQuestionnaireEntry}
            ref={ref}
          />
        ) as any,
        el,
      );
    }

    if (ref.current) {
      ref.current.questionnaireData =
        showQuestionnaire || showQuestionnaireEntry;
      ref.current.unmount = unmount;
    }
    this.currentActiveSurvey = ref.current;
    try {
      startVisibleListen(document.querySelector('.feelgood'), e => {
        sendSlardarEvent(ECustomEvent.SurveyVisibleChange, {
          categories: {
            intersectionRatio: e?.[0].intersectionRatio,
            isIntersecting: e?.[0].isIntersecting,
            targetClass: e?.[0].target.className,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            isVisible: e?.[0].isVisible,
          },
        });
      });
    } catch (e) {
      console.warn('IntersectionObserver api fail');
    }
    return { current: ref.current };
  }

  /**
   * 事件上报
   * @param triggerKey
   * @param param
   * @returns
   */
  private async postEvent(
    triggerKey: EDefaultTriggerKey | string,
    param?: {
      sign?: ReportParam['sign'];
      questionnaireID?: string;
    },
  ) {
    const reportParam = {
      ...this.defaultReportParam,
      sign: param?.sign || this.config.sign || {},
    };
    const submitID =
      param?.questionnaireID &&
      this.questionnairesIDToSubmitIDMap[param?.questionnaireID];
    sendSlardarEvent(ECustomEvent.EventReport, {
      categories: {
        triggerKey,
        reportParam,
      },
    });
    return postEvent(
      this.axiosInstance,
      {
        platID: this.platformDetail?.platID || this.config.platformID,
        paramList: [
          {
            triggerKey,
            eventTime: Math.round(new Date().getTime() / 1000).toString(),
            reportParam,
            questionnaireID: param?.questionnaireID,
          },
        ],
      },
      submitID
        ? {
            headers: {
              'x-feelgood-submit-id': submitID,
            },
          }
        : undefined,
    )
      .then(data => {
        let submitId;
        let questionnaireId;
        if (data?.showType === ShowType.Questionnaire && data?.questionnaire) {
          submitId = data.questionnaire?.submitID;
          questionnaireId = data.questionnaire?.questionnaireID;
        }
        if (data?.showType === ShowType.Entry && data.entry) {
          submitId = data.entry?.submitID;
          questionnaireId = data.entry?.questionnaireID;
        }
        if (questionnaireId && submitId) {
          this.questionnairesIDToSubmitIDMap[questionnaireId] = submitId;
          setSlardarContext({
            submitID: submitId,
            questionnaireID: questionnaireId,
            surveyId: data?.questionnaire?.surveyID || data.entry?.surveyID,
          });
        }

        return data;
      })
      .catch(error => {
        return error;
      });
  }

  /**
   * 提交答案
   * @param SubmitAnswerReq
   * @returns
   */
  private async postAnswer({
    questionnaireID,
    submitCost,
    answerData,
    reportParam,
  }: Omit<
    SubmitAnswerReq,
    'eventTime' | 'accessUserInfo' | 'platID' | 'submitID'
  >) {
    const submitID =
      questionnaireID && this.questionnairesIDToSubmitIDMap[questionnaireID];
    return postAnswer(
      this.axiosInstance,
      {
        platID: this.platformDetail?.platID || this?.config.platformID || '',
        questionnaireID,
        eventTime: Math.round(new Date().getTime() / 1000).toString(),
        reportParam: {
          ...this.defaultReportParam,
          sign: reportParam.sign,
        },
        submitCost,
        answerData,
      },
      submitID
        ? {
            headers: {
              'x-feelgood-submit-id': submitID,
            },
          }
        : undefined,
    )
      .then(data => {
        this.questionnairesIDToSubmitIDMap[questionnaireID] = null;
        return data;
      })
      .catch(() => {
        //
      });
  }

  // dom可见性
  private visibleChange() {
    sendSlardarEvent(ECustomEvent.DomVisibleChange, {
      categories: {
        visibilityState: document.visibilityState,
      },
    });
  }
}

let feelgoodInstance: Feelgood | null = null;
export const feelgood = (config?: IFeelgoodConfig) => {
  if (!feelgoodInstance && config) {
    feelgoodInstance = new Feelgood(config);
  }
  return feelgoodInstance;
};

export * from './types';
export * from './types/feelgood';
