import styles from './style.module.less';

type ITikTokLoadingProps = React.SVGProps<SVGSVGElement>;
export default function Loading({
  width = 24,
  height = 24,
}: ITikTokLoadingProps) {
  return (
    <svg
      className={styles.loading}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 6.25C8.82424 6.25 6.24988 8.82436 6.24988 12C6.24988 15.1756 8.82424 17.75 11.9999 17.75C13.4085 17.75 14.6959 17.2451 15.6958 16.405C16.2244 15.961 17.0129 16.0295 17.457 16.5581C17.901 17.0866 17.8325 17.8751 17.3039 18.3192C15.8704 19.5235 14.0183 20.25 11.9999 20.25C7.44353 20.25 3.74988 16.5563 3.74988 12C3.74988 7.44365 7.44353 3.75 11.9999 3.75C16.5562 3.75 20.2499 7.44365 20.2499 12C20.2499 12.6904 19.6902 13.25 18.9999 13.25C18.3095 13.25 17.7499 12.6904 17.7499 12C17.7499 8.82436 15.1755 6.25 11.9999 6.25Z"
        fill="white"
      />
    </svg>
  );
}
