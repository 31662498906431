import { createModel } from '@byted/hooks';
import { useState } from 'react';
import { Device } from './types/feelgood';
import { defaultTextPlaceHolder } from './common/const';
import { ThemeType } from '.';

const ComponentStore = () => {
  const [defaultText, setDefaultText] = useState<Record<string, string>>({
    default_feedback: 'Thanks for your feedback',
    default_submit: 'Submit',
    nps_0: 'Unwilling',
    nps_10: 'Willing',
    rating_1: 'Very dissatisfied',
    rating_2: 'Not satisfied',
    rating_3: 'General',
    rating_4: 'Satisfied',
    rating_5: 'Very satisfied',
    is_require: 'Please complete required fields',
    mulit_choice_limit: 'Select up to {num} options',
    please_input: 'Please enter',
  });

  const getCurrentDevice = (device?: Device) => {
    if (device === Device.Android || device === Device.IOS) {
      return 'mobile';
    }
    return 'desktop';
  };

  const [questionTheme, setQuestionTheme] = useState<ThemeType['question']>();
  const setThemeData = (
    theme: ThemeType,
    mode = 'light',
    device: string,
    ref: HTMLDivElement,
  ) => {
    setQuestionTheme(theme.question);
    let themeInner = theme?.global?.light;
    if (mode === 'dark') {
      themeInner = { ...themeInner, ...theme?.global?.dark };
    }
    const styleMap = { ...themeInner, ...theme?.global?.[device] };
    if (ref) {
      Object.keys(styleMap).forEach(key => {
        ref.style.setProperty(key, styleMap[key]);
      });
      ref.style.visibility = 'visible';
    }
  };
  return {
    defaultTextSign: defaultTextPlaceHolder,
    defaultText,
    setDefaultText: (defaultObj: Record<string, string> = {}) => {
      setDefaultText({ ...defaultText, ...defaultObj });
    },
    getCurrentDevice,
    questionTheme,
    setThemeData,
  };
};

export default createModel(ComponentStore);
