// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feelgood-deliverer-sdk__rate--vQHPW{align-items:center;display:flex;flex-direction:column;justify-content:center}.feelgood-deliverer-sdk__rate--vQHPW .rr--group .rr--box:not(:last-child){margin:var(--fg-direction-rating-margin)}.feelgood-deliverer-sdk__rate--vQHPW .rr--group .rr--svg{fill:var(--fg-theme-rating-inactive);box-sizing:border-box;height:100%;padding:4px}.feelgood-deliverer-sdk__rate--vQHPW .rr--group .rr--on .rr--svg{fill:var(--fg-theme-rating-active)}.feelgood-deliverer-sdk__rate--vQHPW .rr--fx-colors .rr--svg{transition-duration:.25s}.feelgood-deliverer-sdk__rate__feedback--CoVLn{color:var(--fg-theme-rating-tip);font-size:12px;font-style:normal;font-weight:400;height:12px;margin-top:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rate": "feelgood-deliverer-sdk__rate--vQHPW",
	"rate__feedback": "feelgood-deliverer-sdk__rate__feedback--CoVLn",
	"rateFeedback": "feelgood-deliverer-sdk__rate__feedback--CoVLn"
};
export default ___CSS_LOADER_EXPORT___;
