import { RadioGroup } from '@okee-uikit/react';
import { useMemo } from 'react';
import { useField, observer, Field } from '@formily/react';
import { ObjectField, Field as FieldType } from '@formily/core';
import { cloneDeep, isEmpty } from 'lodash-es';
import { QuestionConfig } from '../../types/feelgood';
import ComponentStore from '../../store';
import CommonText from '../common-text/common-text';
import { shuffle } from '../../util';
import styles from './style.module.less';

export default observer(() => {
  const field = useField<ObjectField>();
  const questionConfig: QuestionConfig = field.data;
  const options = useMemo(() => {
    const options =
      cloneDeep(questionConfig.singleChoiceData?.optionList) || [];
    return !questionConfig.singleChoiceData?.randomConfig.enable
      ? options
      : shuffle(
          options,
          questionConfig.singleChoiceData?.randomConfig.fixedOptionKey,
        );
  }, [questionConfig.singleChoiceData]);
  const { defaultText } = ComponentStore.useContext();
  field.setValidator(val => {
    if (questionConfig.singleChoiceData?.isRequired && isEmpty(val)) {
      return defaultText?.is_require;
    }
    return true;
  });
  return options.length ? (
    <div className={styles['single-choice']}>
      <RadioGroup
        className={styles.radioGroup}
        value={field.value?.optionKey}
        type="radio"
      >
        {options.map(({ optionKey, text, relationInput }) => {
          return (
            <div className={styles['single-choice-item']} key={optionKey}>
              <RadioGroup.Radio
                value={optionKey}
                onValueChange={val => {
                  field.setValue({
                    optionKey,
                    relationInput: '',
                  });
                  if (val) {
                    setTimeout(() => {
                      const reInput = field
                        .query(`${field.path.entire}.relationInput`)
                        .take() as FieldType;
                      reInput?.setRequired(relationInput.isMust);
                      reInput?.validate().catch((e: any) => e);
                    }, 0);
                  }
                  field.validate().catch((e: any) => e);
                }}
              >
                {text}
              </RadioGroup.Radio>
              <div className={styles['single-choice-input']}>
                {relationInput.enable && field.value?.optionKey === optionKey && (
                  <Field name={`relationInput`} required={relationInput.isMust}>
                    {inputField => {
                      return (
                        <CommonText
                          customClassName={styles['single-choice-text']}
                          value={inputField.value}
                          onChange={e => {
                            inputField.setValue(e.target.value);
                            inputField.validate().catch((e: any) => e);
                          }}
                          invalid={inputField.validateStatus === 'error'}
                        />
                      );
                    }}
                  </Field>
                )}
              </div>
            </div>
          );
        })}
      </RadioGroup>
    </div>
  ) : null;
});
