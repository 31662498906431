// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
export enum LogicBoolType {
  And = 1,
  Or = 2,
}

export enum EachUserConditionType {
  Trigger = 1,
  Sign = 2,
  TargetGroup = 3,
}

export enum LogicComparatorType {
  GT = 1,
  LT = 2,
  EQ = 3,
  GE = 4,
  LE = 5,
}

export enum LogicStringComparatorType {
  Eq = 1,
  NotEq = 2,
}

export enum LoginGroupComparatorType {
  In = 1,
  NotIn = 2,
}

/** 问题类型 */
export enum QuestionType {
  Invalid = 0,
  SingleChoice = 1,
  MultiChoice = 2,
  ShortAnswer = 3,
  Rating = 4,
  MultiChoiceTag = 5,
  Description = 6,
  NPS = 7,
}

export enum Device {
  Unknown = 1,
  IOS = 2,
  Android = 3,
  PC = 4,
}

export enum QuestionOrigin {
  URL = 1,
  Embedded = 2,
}

export interface EntranceConfig {
  enable: boolean;
  horizontally: string;
  vertically: string;
  pageLevel: string;
}

export interface EachTranslationProgress {
  language: string;
  total: number;
  complete: number;
}

export interface ShowConfig {
  /** 页面问题配置 */
  pageConfig: Array<EachPageConfig>;
  submitText: string;
  feedbackText: string;
}

/** 问卷页头部配置 */
export interface HeaderConfig {
  bannerURI?: string;
  title?: string;
  instructions?: string;
}

/** 随机序控制参数 */
export interface RandomizeOrderConfig {
  /** 作为ActionReportResp等对外接口的返回值会固定返回false, 由后端控制随机, 前端不管 */
  enable: boolean;
  /** 固定不参与随机的选项key */
  fixedOptionKey?: Array<string>;
}

/** 选项互斥配置 */
export interface OptionConflictConfig {
  enable: boolean;
  /** 互斥的选项key */
  conflictOptionKey: Array<string>;
}


/** 2: required list<string>  optionKeyList; */
/** 展示条件 单项配置 */
export interface EachDisplayConditionConfig {
  questionKey: string;
  isSelected: boolean;
  logicType: LogicBoolType;
  optionKeyList: Array<string>;
}

/** 展示条件配置 */
export interface DisplayConditionConfig {
  enable: boolean;
  logicType: LogicBoolType;
  eachConfig: Array<EachDisplayConditionConfig>;
}

/** 选项选择后关联输入配置 */
export interface OptionRelationInputConfig {
  enable: boolean;
  isMust: boolean;
}

/** 单选题选项配置 */
export interface SingleChoiceOptionConfigData {
  optionKey: string;
  text: string;
  relationInput: OptionRelationInputConfig;
}

/** 单选题配置 */
export interface SingleChoiceData {
  isRequired: boolean;
  titleText: string;
  randomConfig: RandomizeOrderConfig;
  optionList: Array<SingleChoiceOptionConfigData>;
}

/** 多选题选项配置 */
export interface MultiChoiceOptionConfigData {
  optionKey: string;
  text: string;
  relationInput: OptionRelationInputConfig;
  conflictConfig: OptionConflictConfig;
}

/** 多选题配置 */
export interface MultiChoiceData {
  isRequired: boolean;
  titleText: string;
  randomConfig: RandomizeOrderConfig;
  /** 0 即不限制 */
  maxCnt: number;
  optionList: Array<MultiChoiceOptionConfigData>;
}

/** 简单题配置 */
export interface ShortAnswerData {
  isRequired: boolean;
  titleText: string;
  placeholderText: string;
}

/** 评分题选项配置 */
export interface RatingChoiceData {
  /** 分值 */
  score: string;
  text: string;
  optionKey: string;
}

/** 评分题配置 */
export interface RatingData {
  isRequired: boolean;
  titleText: string;
  /** 前端自样式定义 */
  style: number;
  optionList: Array<RatingChoiceData>;
}

/** 多选标签题 选项配置 */
export interface MultiChoiceTagOptionConfigData {
  optionKey: string;
  text: string;
}

/** 多选标签题配置 */
export interface MultiChoiceTagData {
  isRequired: boolean;
  titleText: string;
  randomConfig: RandomizeOrderConfig;
  /** 0 即不限制 */
  maxCnt: number;
  optionList: Array<MultiChoiceTagOptionConfigData>;
}

/** 问题描述配置 */
export interface DescriptionData {
  titleText: string;
}

export interface NPSChoiceOptionData {
  score: string;
  optionKey: string;
}

export interface NPSChoiceData {
  isRequired: boolean;
  s10Text: string;
  s0Text: string;
  optionList: Array<NPSChoiceOptionData>;
  titleText: string;
}

export interface MatrixData {
  isRequired: boolean;
  titleText: string;
  subOptionTitle: Array<string>;
  subQuestionList: Array<MatrixSubQeustionData>;
}

export interface MatrixSubQeustionData {
  titleText: string;
  optionKeyList: Array<string>;
}

/** 问题配置 */
export interface QuestionConfig {
  questionType: QuestionType;
  questionKey: string;
  displayCondition: DisplayConditionConfig;
  singleChoiceData?: SingleChoiceData;
  multiChoiceData?: MultiChoiceData;
  shortAnswerData?: ShortAnswerData;
  ratingData?: RatingData;
  multiChoiceTagData?: MultiChoiceTagData;
  descriptionData?: DescriptionData;
  npsChoiceData?: NPSChoiceData;
  matrixData?: MatrixData;
}

/** 每页配置 */
export interface EachPageConfig {
  headerConfig?: HeaderConfig;
  questionList: Array<QuestionConfig>;
}

/** 调研的入口设置 */
export interface SurveyEntranceConfig {
  styleURI: string;
  entranceConfig?: EntranceConfig;
  enable: boolean;
}

/** 展示时机 */
/** 类型:延迟进入页面 参数为  进入页面事件@view    + delay time > 0 */
/** 类型:默认              进入页面事件@view     + delay time = 0 */
/** 类型:用户行为           其他用户平台自定义事件 + delay time = 0 */
export interface AppearConditionConfig {
  /** 进入页面事件 */
  triggerKey: string;
  /** 秒 */
  delayTime: number;
}

/** 展示后展开时机 */
/** 类型:默认         enable = false */
/** 类型:每xx天首次    enable = true , cycle=xx */
/** 类型:默认展开      enable = true, cycle=0 */
export interface PopupConditionConfig {
  /** false 默认不展开 */
  enable: boolean;
  /** 0 每次默认展开 */
  cycle: number;
}

/** 用户行为(触发事件配置) */
export interface EachUserConditionTypeTriggerParam {
  cycle: number;
  /** 循环周期 */
  triggerKey: string;
  /** 比较符 */
  comparator: LogicComparatorType;
  /** 比较右值 */
  rightValue: string;
  /** 判断频率的自定义标志 */
  signID: string;
}

/** 用户属性(自定义标志配置) */
export interface EachUserConditionTypeSignParam {
  /** 自定义标志ID */
  signID: string;
  /** 字符串逻辑操作类型 */
  logicType: LogicStringComparatorType;
  /** 右值, 直接传入category 的字符串 , 或用户自填值 */
  rightValue: Array<string>;
}

/** 人群包条件 */
export interface EachUserConditionTypeTargetGroupParam {
  /** 1: required list<i64>                       groupIDList; */
  logicType: LoginGroupComparatorType;
  groupID: string;
}

/** 单个用户限制条件 */
export interface EachUserCondition {
  /** 条件类型 */
  type: EachUserConditionType;
  paramConfig?: EachUserConditionTypeSignParam;
  triggerParam?: EachUserConditionTypeTriggerParam;
  groupParam?: EachUserConditionTypeTargetGroupParam;
}

/** 用户限制 */
export interface UserCondition {
  enable: boolean;
  logicType: LogicBoolType;
  conditionList: Array<EachUserCondition>;
}

export interface EachPathCondition {
  domainID: string;
  /** 路径  , 支持* 占位符 */
  path: string;
}

/** 域名路径限制 */
export interface PathCondition {
  /** false 的时候 默认只能由指定domain内请求, 不限制path */
  enable: boolean;
  conditionList?: Array<EachPathCondition>;
}

/** 定时配置 */
export interface TimingCondition {
  /** -1 表示不定时,  正数的时候标识有预期投放时间, 不能小于当前时间 */
  startTime: string;
  /** -1 表示不定时,  正数的时候标识有预期投放时间, 不能小于startTime */
  endTime: string;
  /** -1 表示不限制 */
  maxCnt: string;
  /** -1 表示不限制 */
  maxCntTTP: string;
  /** -1 表示不限制 */
  maxCntEuttp?: string;
}

/** 单个频率限制条件 */
export interface EachFrequencyCondition {
  timePeriod: number;
  /** @send 发放问卷(看见入口) */
  triggerKey: string;
  maxCnt: number;
  signID: string;
  enable: boolean;
}

/** 频率限制 */
export interface FrequencyCondition {
  enable: boolean;
  conditionList: Array<EachFrequencyCondition>;
}

/** 投放上限 */
export interface UpperLimitCondition {
  enable: boolean;
  signID: string;
  limit: string;
  ttpLimit: string;
  euttpLimit?: string;
}

/** 独立问卷提交配置 */
export interface IndependentSurveyCommitSetting {
  allowDuplicate: boolean;
}

/** 独立问卷嵌入配置 */
export interface IndependentSurveyEmbeddedConfig {
  enable: boolean;
  entranceConfig: SurveyEntranceConfig;
  appearConditionConfig: AppearConditionConfig;
  popupConditionConfig: PopupConditionConfig;
  userCondition: UserCondition;
  pathCondition: PathCondition;
  frequencyCondition: FrequencyCondition;
  upperLimitCondition: UpperLimitCondition;
}

/** 嵌入问卷嵌入配置 */
export interface EmbeddedSurveyEmbeddedConfig {
  entranceConfig: SurveyEntranceConfig;
  appearConditionConfig: AppearConditionConfig;
  popupConditionConfig: PopupConditionConfig;
  userCondition: UserCondition;
  pathCondition: PathCondition;
  frequencyCondition: FrequencyCondition;
  upperLimitCondition: UpperLimitCondition;
}

/** 通用配置 */
export interface CommonSurveyConfig {
  /** 没有配置多语言就传空吧 */
  langList: Array<string>;
  timingCondition: TimingCondition;
  theme: string;
}

export interface ActionReportParam {
  /** 除了用户自定义的还有一些自定义的   , */
  triggerKey: string;
  eventTime: string;
  reportParam: ReportParam;
  /** 问卷相关事件需要上报  questionnaireID和submitID */
  questionnaireID?: string;
  submitID?: string;
}

export interface ShowEachPageConfig {
  headerConfig?: HeaderConfig;
  questionList: Array<QuestionConfig>;
}

export interface ShowQuestionnaire {
  questionnaireID: string;
  isAutoPopup: boolean;
  entranceConfig: SurveyEntranceConfig;
  delay: string;
  showConfig: ShowConfig;
  /** 返回的问卷带有该ID, 后续每次问卷相关事件上报都必须携带此ID, 有效期7天 */
  submitID: string;
  surveyID: string;
  surveyVersion: number;
  theme?: string;
  defaultTranslation: { [key: string]: string };
}

export interface ShowQuestionnaireEntry {
  questionnaireID: string;
  isAutoPopup: boolean;
  entranceConfig: SurveyEntranceConfig;
  delay: string;
  tips: string;
  /** 返回的问卷带有该ID, 后续每次问卷相关事件上报都必须携带此ID, 有效期7天 */
  submitID: string;
  surveyID: string;
  surveyVersion: number;
  theme: string;
}

export interface ShortAnswerAnswerData {
  answer: string;
}

export interface SingleChoiceAnswerData {
  optionKey: string;
  relationInput?: string;
}

export interface MultichoiceAnswerData {
  optionKey: string;
  relationInput?: string;
}

export interface MultiChoiceAnswerData {
  answerList: Array<MultichoiceAnswerData>;
}

export interface MultiChoiceTagAnswerData {
  optionKeyList: Array<string>;
}

export interface RatingAnswerData {
  optionKey: string;
}

export interface NpsAnswerData {
  optionKey: string;
}

export interface MatrixAnswerData {
  optionKey: Array<string>;
}

export interface QuestionAnswer {
  questionKey: string;
  questionType: QuestionType;
  answerData: AnswerData;
  /** 问题是显示的第几个 */
  showIndex: number;
}

export interface ReportParam {
  /** 用户自定义标识 */
  sign: { [key: string]: string };
  srcIP: string;
  userAgent: string;
  referUrl: string;
  device: Device;
  /** ISO 639-1 标准 */
  language: string;
  /** 问卷相关需要上报 */
  questionOrigin?: QuestionOrigin;
  deviceID: string;
}

/** 独立问卷提交配置 */
export interface IndependentSurveySetting {
  allowDuplicate: boolean;
}

export interface SurveyConfig {
  /** 独立问卷嵌入式配置 */
  independentEmbeddedSurveyConfig?: IndependentSurveyEmbeddedConfig;
  /** 嵌入式问卷嵌入式配置 */
  embeddedSurveyConfig?: EmbeddedSurveyEmbeddedConfig;
  /** 问卷通用配置 */
  commonSurveyConfig: CommonSurveyConfig;
  /** 独立问卷提交配置 */
  independentSurveySetting?: IndependentSurveySetting;
}

export interface QuestionConfigData {
  singleChoiceData?: SingleChoiceData;
  multiChoiceData?: MultiChoiceData;
  shortAnswerData?: ShortAnswerData;
  ratingData?: RatingData;
  multiChoiceTagData?: MultiChoiceTagData;
}

export interface AnswerData {
  singleChoiceAnswerData?: SingleChoiceAnswerData;
  shortAnswerDataAnswerData?: ShortAnswerAnswerData;
  multiChoiceAnswerData?: MultiChoiceAnswerData;
  multiChoiceTagAnswerData?: MultiChoiceTagAnswerData;
  ratingAnswerData?: RatingAnswerData;
  npsAnswerData?: NpsAnswerData;
  matrixAnswerData?: MatrixAnswerData;
}
