import { useMemo } from 'react';
import { useInView } from '@byted/hooks';
import { Field } from '@formily/core';
import { observer, useField } from '@formily/react';
import classNames from 'classnames';
import { QuestionConfig, QuestionType } from '../../types/feelgood';
import styles from './style.module.less';

export const QuestionDataString = {
  [QuestionType.Invalid]: 'invalid',
  [QuestionType.SingleChoice]: 'singleChoiceData',
  [QuestionType.MultiChoice]: 'multiChoiceData',
  [QuestionType.MultiChoiceTag]: 'multiChoiceTagData',
  [QuestionType.ShortAnswer]: 'shortAnswerData',
  [QuestionType.Rating]: 'ratingData',
  [QuestionType.NPS]: 'npsChoiceData',
  [QuestionType.Description]: 'descriptionData',
  [QuestionType.Matrix]: 'matrixData',
};
const QuestionDecorator: React.FC<{
  question: QuestionConfig;
}> = observer(({ children, question }) => {
  const field = useField<Field>();
  const [itemRef] = useInView<HTMLDivElement>();
  const [decoratorRef] = useInView<HTMLDivElement>();
  const title = useMemo(() => {
    const questionPrefix = QuestionDataString[question.questionType];
    const title = (question as any)[questionPrefix as any]?.titleText;
    return title;
  }, [question]);
  const isRequire = useMemo(() => {
    const questionPrefix = QuestionDataString[question.questionType];
    return (question as any)[questionPrefix as any]?.isRequired || false;
  }, [question]);
  return (
    <div
      className={classNames(
        styles['questionnaire__question-decorator'],
        'feelgood-questionnaire_question-decorator',
      )}
      data-question-key={question.questionKey}
      data-question-type={question.questionType}
      // style={style}
      ref={decoratorRef}
    >
      <div
        className={classNames(
          styles['questionnaire__question-item'],
          'feelgood-questionnaire_question-item',
        )}
        ref={itemRef}
      >
        {title ? (
          <div
            className={classNames(
              styles['questionnaire__question-title'],
              'feelgood-questionnaire_question-item-title',
            )}
          >
            {isRequire && <span className={styles.require}>*</span>}
            {title}
          </div>
        ) : null}
        {children}
        <div
          className={classNames(
            styles.validateError,
            'feelgood-questionnaire_question-validate',
          )}
        >
          {field.selfErrors}
        </div>
      </div>
      {/* 题目尾部空元素，用于滚动定位 */}
      <div
        className={styles['questionnaire__question-decorator_bottom']}
        id={`decorator-bottom-${question.questionKey}`}
      ></div>
    </div>
  );
});
export default QuestionDecorator;
