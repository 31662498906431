import { useBoolean } from '@byted/hooks';
import { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import {
  ClosePosEnum,
  IQuestionnaireProps,
} from '../questionnaire/questionnaire';
import ComponentStore from '../../store';
import Container from './container';
import styles from './style.module.less';
import Entry from './entry';
import '../../feelgood.less';
import Mask from './mask';

const Widgets: React.FC<IQuestionnaireProps> = props => {
  const {
    mode = 'light',
    theme,
    style = {},
    entryUrl,
    entranceConfig = {
      enable: true,
    },
    device,
    isAutoPopup = false,
    preview,
    mobileHasMask = false,
    onEnd,
    onClose,
    onEntryClick,
    onEntryShow,
  } = props;
  const showEntry = useBoolean(false);
  const showContainer = useBoolean(false);
  const showContainerAnime = useBoolean(false);
  const { getCurrentDevice, setThemeData } = ComponentStore.useContext();
  const feelgoodRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (theme && mode && feelgoodRef.current) {
      setThemeData(theme, mode, getCurrentDevice(device), feelgoodRef.current);
    }
  }, [theme, mode, device, feelgoodRef]);

  useEffect(() => {
    if (isAutoPopup) {
      showContainer.setTrue();
      setTimeout(() => {
        showContainerAnime.setTrue();
      }, 0);
    }
    if (entranceConfig?.enable) {
      showEntry.setTrue();
    }
  }, [entranceConfig, isAutoPopup]);

  useEffect(() => {
    if (showEntry.state) {
      onEntryShow?.();
    }
  }, [onEntryShow, showEntry.state]);

  const clickClose = (closePos: ClosePosEnum) => {
    if (!showEntry.state) {
      onClose?.(closePos, true);
      if (preview) {
        showContainer.setFalse();
        showContainerAnime.setFalse();
        setTimeout(() => {
          showContainer.setTrue();
          setTimeout(() => {
            showContainerAnime.setTrue();
          }, 0);
        }, 1000);
      } else {
        showContainerAnime.setFalse();
        showContainer.setFalse();
        onEnd?.();
      }
    } else {
      onClose?.(closePos, false);
      showContainerAnime.setFalse();
      showContainer.setFalse();
    }
  };

  const clickMask = (closePos: ClosePosEnum, trueClose?: boolean) => {
    onClose?.(closePos, trueClose);
    showContainerAnime.setFalse();
    showContainer.setFalse();
  };

  const deviceStr = useMemo(() => {
    return getCurrentDevice(device);
  }, [getCurrentDevice, device]);
  return (
    <div
      className={classNames(
        'feelgood',
        `feelgood__theme--${mode}`,
        `feelgood__device--${deviceStr}`,
        styles[`questionnaire__widgets-${deviceStr}`],
      )}
      id="feelgood-questionnaire-widgets"
      ref={feelgoodRef}
      style={{
        visibility: 'hidden',
        left: style?.left,
        top: style?.top,
        right: style?.right,
        bottom: style?.bottom,
        zIndex: style?.zIndex,
        flexDirection: style?.popDirection === 'right' ? 'row-reverse' : 'row',
      }}
    >
      {deviceStr === 'mobile' &&
        showContainer.state &&
        Boolean(mobileHasMask) && <Mask {...props} onClose={clickMask} />}
      {showContainer.state && (
        <Container
          show={showContainerAnime.state}
          {...props}
          onClose={clickClose}
          onEnd={() => {
            showContainerAnime.setFalse();
            showContainer.setFalse();
            onEnd?.();
          }}
        />
      )}
      {showEntry.state && (
        <Entry
          containerShow={showContainerAnime.state}
          entryUrl={entryUrl}
          onClick={() => {
            if (onEntryClick?.(showContainer.state)) {
              return;
            }
            if (showContainer.state) {
              onClose?.(ClosePosEnum.Entry, false);
            }
            showContainer.toggle();
            setTimeout(() => {
              showContainerAnime.toggle();
            }, 0);
          }}
        />
      )}
    </div>
  );
};

export default function WidgetsMain(prop: IQuestionnaireProps) {
  return (
    <ComponentStore.Provider>
      <Widgets {...prop} />
    </ComponentStore.Provider>
  );
}
