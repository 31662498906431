export function isRTL(lng: string) {
  const rtlLngs = [
    'ar',
    'shu',
    'sqr',
    'ssh',
    'xaa',
    'yhd',
    'yud',
    'aao',
    'abh',
    'abv',
    'acm',
    'acq',
    'acw',
    'acx',
    'acy',
    'adf',
    'ads',
    'aeb',
    'aec',
    'afb',
    'ajp',
    'apc',
    'apd',
    'arb',
    'arq',
    'ars',
    'ary',
    'arz',
    'auz',
    'avl',
    'ayh',
    'ayl',
    'ayn',
    'ayp',
    'bbz',
    'pga',
    'he',
    'iw',
    'ps',
    'pbt',
    'pbu',
    'pst',
    'prp',
    'prd',
    'ug',
    'ur',
    'ydd',
    'yds',
    'yih',
    'ji',
    'yi',
    'hbo',
    'men',
    'xmn',
    'fa',
    'jpr',
    'peo',
    'pes',
    'prs',
    'dv',
    'sam',
    'ckb',
  ];
  const lngTag = lng.toLowerCase().split('-')[0];
  return rtlLngs.includes(lngTag) || lng.toLowerCase().indexOf('-arab') > 1;
}
