import { Field as SingleField, ArrayField, ObjectField } from '@formily/react';
import {
  SingleChoice,
  MultiChoice,
  MultiChoiceTag,
  Rating,
  ShortAnswer,
  Nps,
} from '../../components';
import {
  AnswerData,
  MultichoiceAnswerData,
  QuestionConfig,
  QuestionType,
} from '../../types/feelgood';
import QuestionDecorator from './question-decorator';

/**
 * 不参与整体逻辑的题型
 */
export const inputTypeQuestion: QuestionType[] = [
  QuestionType.ShortAnswer,
  QuestionType.Description,
];
/**
 * 题型到组件映射map
 */
export const questionTypeToFieldComponentMap: Record<
  number,
  { field: any; component: any }
> = {
  [QuestionType.SingleChoice]: {
    field: ObjectField,
    component: SingleChoice,
  },
  [QuestionType.MultiChoice]: {
    field: ArrayField,
    component: MultiChoice,
  },
  [QuestionType.NPS]: {
    field: SingleField,
    component: Nps,
  },
  [QuestionType.ShortAnswer]: {
    field: SingleField,
    component: ShortAnswer,
  },
  [QuestionType.MultiChoiceTag]: {
    field: ArrayField,
    component: MultiChoiceTag,
  },
  [QuestionType.Rating]: {
    field: SingleField,
    component: Rating,
  },
};

export const getQuestionAnswerData = (
  type: QuestionType,
  value: any,
): AnswerData => {
  switch (type) {
    case QuestionType.Rating:
      return {
        ratingAnswerData: {
          optionKey: value,
        },
      };
    case QuestionType.MultiChoiceTag:
      return {
        multiChoiceTagAnswerData: {
          optionKeyList: value,
        },
      };
    case QuestionType.ShortAnswer:
      return {
        shortAnswerDataAnswerData: {
          answer: ((value as string) || '')?.trim(),
        },
      };
    case QuestionType.SingleChoice:
      return {
        singleChoiceAnswerData: {
          optionKey: value.optionKey,
          relationInput: value.relationInput.trim(),
        },
      };
    case QuestionType.MultiChoice:
      return {
        multiChoiceAnswerData: {
          answerList: (value as MultichoiceAnswerData[])
            .filter(i => i.optionKey)
            .map(item => {
              return {
                ...item,
                relationInput: item?.relationInput?.trim(),
              };
            }),
        },
      };
    case QuestionType.NPS:
      return {
        npsAnswerData: {
          optionKey: value,
        },
      };
    default:
      return undefined as any;
  }
};

/**
 * 题目
 */
export default function Question({
  question,
  index,
}: {
  question: QuestionConfig;
  index?: number;
}) {
  const { questionKey, questionType, displayCondition } = question;
  const { field: Field, component } =
    questionTypeToFieldComponentMap[questionType];
  return (
    component && (
      <Field
        key={questionKey}
        // 如果题目有逻辑关联，一开始不显示
        visible={!displayCondition?.enable}
        name={questionKey}
        decorator={[QuestionDecorator, { question, index }]}
        component={[component]}
        data={{ ...question, questionIndex: index }}
      />
    )
  );
}
