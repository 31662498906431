// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feelgood-deliverer-sdk__showAnswerBox--sbVO1{position:relative}.feelgood-deliverer-sdk__showAnswerBox--sbVO1 .feelgood-deliverer-sdk__showAnswerBottom--dNXJU{bottom:50px;height:0;left:0;position:absolute;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showAnswerBox": "feelgood-deliverer-sdk__showAnswerBox--sbVO1",
	"showAnswerBottom": "feelgood-deliverer-sdk__showAnswerBottom--dNXJU"
};
export default ___CSS_LOADER_EXPORT___;
