import { ReportParam, ActionReportResp } from '@byted-feelgood/components';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

export const postEvent = async (
  instance: AxiosInstance,
  params: {
    platID: string;
    paramList: {
      triggerKey: string;
      eventTime: string;
      reportParam: Omit<ReportParam, 'srcIP' | 'userAgent'>;
      questionnaireID?: string;
    }[];
  },
  config?: AxiosRequestConfig,
): Promise<ActionReportResp> => {
  /**
   * URL query 添加event参数，以便debug
   */
  const event =
    params?.paramList?.length === 1 ? params?.paramList?.[0]?.triggerKey : '';
  const language = params?.paramList?.[0]?.reportParam?.language;
  const { data } = await instance.post(
    `/api/feelgood/v1/event${event ? `?${event}` : ''}${
      language ? `&language=${language}` : ''
    }`,
    params,
    config,
  );
  return data;
};
