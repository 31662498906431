// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feelgood-deliverer-sdk__feelgood-common-text--k_GLt{-webkit-appearance:none;background-color:var(--fg-theme-textarea-bg);border:1px solid var(--fg-theme-textarea-border);border-radius:var(--fg-theme-border-radius);caret-color:var(--fg-theme-textarea-caret);color:var(--fg-theme-question-content);display:flex;font-size:14px;justify-content:center;line-height:20px;min-height:34px;padding:6px 14px;width:100%}.feelgood-deliverer-sdk__feelgood-common-text--k_GLt:hover{background-color:var(--fg-theme-textarea-bg-select)}.feelgood-deliverer-sdk__feelgood-common-text--k_GLt:focus,.feelgood-deliverer-sdk__feelgood-common-text--k_GLt:focus-visible{background-color:var(--fg-theme-textarea-bg-select);border:1px solid var(--fg-theme-textarea-border-select);outline:none}.feelgood-deliverer-sdk__feelgood-common-text--k_GLt::-moz-placeholder{color:var(--fg-theme-textarea-placeholder)}.feelgood-deliverer-sdk__feelgood-common-text--k_GLt:-ms-input-placeholder{color:var(--fg-theme-textarea-placeholder)}.feelgood-deliverer-sdk__feelgood-common-text--k_GLt::placeholder{color:var(--fg-theme-textarea-placeholder)}.feelgood-deliverer-sdk__feelgood-common-text--k_GLt:after{content:\"\"}.feelgood-deliverer-sdk__feelgood-common-text--k_GLt::-webkit-scrollbar{background:transparent;width:4px}.feelgood-deliverer-sdk__feelgood-common-text-invalid--LpI61:focus{border:1px solid var(--fg-theme-error);outline:none}.feelgood-deliverer-sdk__feelgood-common-text-error--DD2N0{color:var(--fg-theme-error);font-size:12px;margin-top:2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feelgood-common-text": "feelgood-deliverer-sdk__feelgood-common-text--k_GLt",
	"feelgoodCommonText": "feelgood-deliverer-sdk__feelgood-common-text--k_GLt",
	"feelgood-common-text-invalid": "feelgood-deliverer-sdk__feelgood-common-text-invalid--LpI61",
	"feelgoodCommonTextInvalid": "feelgood-deliverer-sdk__feelgood-common-text-invalid--LpI61",
	"feelgood-common-text-error": "feelgood-deliverer-sdk__feelgood-common-text-error--DD2N0",
	"feelgoodCommonTextError": "feelgood-deliverer-sdk__feelgood-common-text-error--DD2N0"
};
export default ___CSS_LOADER_EXPORT___;
