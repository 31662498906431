import { useMemo, useState } from 'react';
import { useField, observer } from '@formily/react';
import { Field } from '@formily/core';
import { Rating } from '@smastrom/react-rating';
import { QuestionConfig } from '../../types/feelgood';
import ComponentStore from '../../store';
import styles from './style.module.less';
import '@smastrom/react-rating/style.css';

const StarDrawing = (
  <path d="M17.0206 3.61597C17.3622 2.79468 18.5257 2.79468 18.8673 3.61597L22.4705 12.279C22.6145 12.6253 22.9401 12.8618 23.3139 12.8918L32.6664 13.6416C33.553 13.7127 33.9126 14.8192 33.237 15.3979L26.1114 21.5017C25.8266 21.7457 25.7022 22.1285 25.7892 22.4932L27.9662 31.6197C28.1726 32.4849 27.2314 33.1688 26.4723 32.7051L18.4652 27.8144C18.1452 27.619 17.7427 27.619 17.4227 27.8144L9.41566 32.7051C8.65656 33.1688 7.71531 32.4849 7.92169 31.6197L10.0987 22.4932C10.1857 22.1285 10.0613 21.7457 9.77654 21.5017L2.65091 15.3979C1.97537 14.8192 2.3349 13.7127 3.22155 13.6416L12.5741 12.8918C12.9479 12.8618 13.2735 12.6253 13.4175 12.279L17.0206 3.61597Z" />
); // Source: https://www.svgrepo.com/svg/118939/star

const customStyles = {
  itemShapes: StarDrawing,
  activeFillColor: '#FE2C55',
  inactiveFillColor: 'rgba(22, 24, 35, 0.12)',
};

/**
 * 评分
 */
export default observer(() => {
  const [hoveredText, setHoveredText] = useState('');
  const { defaultText, defaultTextSign } = ComponentStore.useContext();
  const field = useField<Field<any, any, any, string>>();
  const questionConfig: QuestionConfig = field.data;
  const options = useMemo(() => {
    const ratingOptions = questionConfig.ratingData?.optionList.map(item => {
      return {
        ...item,
        text:
          item.text === defaultTextSign
            ? defaultText?.[`rating_${item.score}`]
            : item.text,
      };
    });
    return ratingOptions || [];
  }, [defaultText, questionConfig.ratingData?.optionList]);
  const [value, text] = useMemo(() => {
    const selected = options?.find(
      ({ optionKey }) => optionKey === field.value,
    );
    if (selected) {
      return [Number(selected.score), selected.text];
    } else {
      return [0, ''];
    }
  }, [field.value, options]);
  const feedbackText = useMemo(() => hoveredText || text, [hoveredText, text]);
  field.setValidator(val => {
    if (questionConfig.ratingData?.isRequired && !val) {
      return defaultText?.is_require;
    }
    return true;
  });
  return options?.length ? (
    <div className={styles.rate}>
      <Rating
        style={{ width: 244 }}
        value={value}
        onChange={newValue => {
          const option = options.find(
            ({ score }) => Number(score) === newValue,
          );
          if (option?.optionKey) {
            field.setValue(option?.optionKey);
          }
          field.validate();
        }}
        onHoverChange={newValue => {
          const text = options.find(
            ({ score }) => Number(score) === newValue,
          )?.text;
          setHoveredText(text || '');
        }}
        itemStyles={customStyles}
        transition="zoom"
        // spaceBetween="none"
        spaceInside="none"
      />
      <p className={styles.rate__feedback}>{feedbackText}</p>
    </div>
  ) : null;
});
