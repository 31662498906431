import { GeneralField, VoidField, DataField, Form } from '@formily/core';
import { isArray, isEmpty } from 'lodash-es';
import {
  EachDisplayConditionConfig,
  LogicBoolType,
  QuestionConfig,
  QuestionType,
  MultichoiceAnswerData,
} from '../../types/feelgood';
import { inputTypeQuestion } from './question';

/**
 * 判断整体逻辑每一条是否符合当前输入
 * @param value
 * @param config
 * @returns
 */
const conditionCount = (
  originValue: any,
  config: EachDisplayConditionConfig,
  questionType: QuestionType,
) => {
  let value = originValue;
  // 根据题型不同的答案结构，转换成optionKey:string或array[optionKey:string]
  if (questionType === QuestionType.SingleChoice) {
    value = originValue?.optionKey;
  }
  if (questionType === QuestionType.MultiChoice) {
    value = originValue?.map((item: MultichoiceAnswerData) => item.optionKey);
  }
  // 判断题目选项
  const isArrayValueMatchAll =
    isArray(value) && config.optionKeyList.every(key => value.includes(key));
  /**
   * 单选不能匹配 AND 的规则
   */
  // const isSingleValueMatchAll = false;
  const isArrayValueMatchOne =
    isArray(value) && value.some(item => config.optionKeyList.includes(item));
  const isSingleValueMatchOne =
    !isArray(value) && config.optionKeyList.includes(value);
  const isMatchAnd =
    config.logicType === LogicBoolType.And && isArrayValueMatchAll;
  const isMatchOr =
    config.logicType === LogicBoolType.Or &&
    (isArrayValueMatchOne || isSingleValueMatchOne);
  return isMatchAnd || isMatchOr;
};
/**
 * 输入整体逻辑的题后，进行滚动
 * @param canShow
 * @param valueChangeField
 */
const scrollToNextQuestion = (
  canShow: boolean,
  valueChangeField: DataField,
) => {
  if (
    canShow &&
    !isEmpty(valueChangeField.value) &&
    !inputTypeQuestion.includes(valueChangeField?.data?.questionType)
  ) {
    setTimeout(() => {
      const ele = document.querySelector(
        `#decorator-bottom-${valueChangeField?.data?.questionKey}`,
      );
      ele?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 0);
  }
};

export const questionDisplayCondition = (
  questionList: QuestionConfig[],
  valueChangeField: DataField,
  form: Form<any>,
  autoScroll: boolean,
) => {
  /**
   * 不参与联动
   */
  if (
    !valueChangeField?.data ||
    inputTypeQuestion.includes(valueChangeField?.data?.questionType)
  ) {
    return;
  }
  /**
   * 是否存在题目是有条件显示的
   */
  const [, ...restQuestionList] = questionList;
  const hasQuestionDisplayCondition = restQuestionList.some(
    ({ displayCondition }) => displayCondition.enable,
  );
  /**
   *   存在条件显示的题目时，当表单项value变化，遍历全部问题配置，处理题目的条件显示
   */
  if (hasQuestionDisplayCondition) {
    questionList
      ?.filter(
        ({ displayCondition }) =>
          // 只处理开启条件控制的
          displayCondition?.enable && displayCondition.eachConfig?.length,
      )
      .forEach(({ displayCondition, questionKey }) => {
        const field = form.fields[questionKey] as Exclude<
          GeneralField,
          VoidField
        >;
        if (field) {
          /**
           * 遍历所有条件：题目维度
           */
          let canShow = false;
          // 只处理有选项需求的
          const eachConfig = displayCondition.eachConfig.filter(
            ({ optionKeyList }) => optionKeyList.length,
          );
          const loopValueFunc = (config: EachDisplayConditionConfig) => {
            /**
             * 要求匹配的题目的 已填入的值
             */
            const {
              value,
              data: { questionType },
            } = form.fields[config.questionKey] as Exclude<
              GeneralField,
              VoidField
            >;
            // 判断每一条逻辑是否满足
            return conditionCount(value, config, questionType);
          };
          if (displayCondition.logicType === LogicBoolType.And) {
            // 全部条件都满足
            canShow = eachConfig.every(loopValueFunc);
          }
          if (displayCondition.logicType === LogicBoolType.Or) {
            // 任一条件满足
            canShow = eachConfig.some(loopValueFunc);
          }
          field.setDisplay(canShow ? 'visible' : 'none');
          // 输入整体逻辑的题后，如果有关联题型、同时有输入值，进行滚动
          autoScroll && scrollToNextQuestion(canShow, valueChangeField);
        }
      });
  }
};
