import { useField, observer } from '@formily/react';
import { Field } from '@formily/core';
import { CSSProperties, useRef, useState } from 'react';
import { QuestionConfig } from '../../types/feelgood';
import ComponentStore from '../../store';
import CommonText from '../common-text/common-text';
import styles from './style.module.less';

export default observer(() => {
  const field = useField<Field<any, any, any, string>>();
  const questionConfig: QuestionConfig = field.data;
  const { defaultText } = ComponentStore.useContext();
  const [style, setStyle] = useState<CSSProperties>();
  const ref = useRef<HTMLDivElement>(null);
  field.setValidator(val => {
    if (questionConfig.shortAnswerData?.isRequired && !val?.trim()) {
      return defaultText?.is_require;
    }
    return true;
  });
  return (
    <div className={styles.showAnswerBox} style={style} ref={ref}>
      <CommonText
        value={field.value || ''}
        onChange={e => {
          field.setValue(e.target.value);
          field.validate();
          setTimeout(() => {
            // 手动设置高度，兼容 iOS Safari 下，全选文本并删除后，父元素高度没有变化，底部submit按钮没有顶上去
            setStyle(
              e.target.value
                ? undefined
                : {
                    height: `${ref.current?.clientHeight || 34}px`,
                  },
            );
          }, 100);
        }}
        placeholder={questionConfig.shortAnswerData?.placeholderText}
        onFocus={textarea => {
          setTimeout(() => {
            const e = document.querySelector(
              `#show-answer-${field?.data?.questionKey}`,
            );
            e?.scrollIntoView({
              behavior: 'smooth',
            });
            // 控制光标到末尾
            textarea.target.selectionStart =
              textarea?.target?.value?.length || 0;
          }, 250);
        }}
      />
      {/* showAnswer滚动定位到倒数第二行 */}
      <div
        className={styles.showAnswerBottom}
        id={`show-answer-${field?.data?.questionKey}`}
      ></div>
    </div>
  );
});
