// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feelgood-deliverer-sdk__questionnaire__widgets-desktop--hWaTT{align-items:var(--fg-theme-entry-align);display:flex;flex-direction:var(--fg-theme-entry-position);gap:16px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.feelgood-deliverer-sdk__questionnaire__widgets-desktop--hWaTT,.feelgood-deliverer-sdk__questionnaire__widgets-mobile--QzL3D{bottom:var(--fg-theme-widgets-bottom);left:var(--fg-theme-widgets-left);position:fixed;right:var(--fg-theme-widgets-right);top:var(--fg-theme-widgets-top);z-index:var(--fg-theme-widgets-zindex)}.feelgood-deliverer-sdk__questionnaire__widgets-mobile--QzL3D{width:100%}.feelgood-deliverer-sdk__questionnaire__widgets-mobile--QzL3D .feelgood-deliverer-sdk__entry--Fngic{bottom:var(--fg-theme-entry-bottom);left:var(--fg-theme-entry-left);position:absolute;right:var(--fg-theme-entry-right);top:var(--fg-theme-entry-top);z-index:1}.feelgood-deliverer-sdk__questionnaire__entry--PC7C8{align-items:center;background:var(--fg-theme-entry);border-radius:50%;box-shadow:0 6px 14px rgba(0,0,0,.15);cursor:pointer;display:flex;height:var(--fg-theme-entry-size);justify-content:center;transition:transform .2s ease;width:var(--fg-theme-entry-size)}.feelgood-deliverer-sdk__questionnaire__entry--PC7C8 svg{height:var(--fg-theme-entry-icon-size);width:var(--fg-theme-entry-icon-size)}.feelgood-deliverer-sdk__questionnaire__entry--PC7C8 path{stroke:var(--fg-theme-entry-icon)}.feelgood-deliverer-sdk__questionnaire__entry--PC7C8 .feelgood-deliverer-sdk__customImg--AbxMY{height:100%;width:100%}.feelgood-deliverer-sdk__questionnaire__entry--PC7C8:hover{box-shadow:0 6px 20px 0 rgba(0,0,0,.3);transform:translateY(-4px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionnaire__widgets-desktop": "feelgood-deliverer-sdk__questionnaire__widgets-desktop--hWaTT",
	"questionnaireWidgetsDesktop": "feelgood-deliverer-sdk__questionnaire__widgets-desktop--hWaTT",
	"questionnaire__widgets-mobile": "feelgood-deliverer-sdk__questionnaire__widgets-mobile--QzL3D",
	"questionnaireWidgetsMobile": "feelgood-deliverer-sdk__questionnaire__widgets-mobile--QzL3D",
	"entry": "feelgood-deliverer-sdk__entry--Fngic",
	"questionnaire__entry": "feelgood-deliverer-sdk__questionnaire__entry--PC7C8",
	"questionnaireEntry": "feelgood-deliverer-sdk__questionnaire__entry--PC7C8",
	"customImg": "feelgood-deliverer-sdk__customImg--AbxMY"
};
export default ___CSS_LOADER_EXPORT___;
