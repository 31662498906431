// @ts-nocheck
/* eslint-disable */
/* tslint:disable */

import { ClientInvokeOptions } from "@byted-service/rpc"
import * as base from './base';
import * as target_group_callback from './target_group_callback';

export { base, target_group_callback };

export enum AccessUserType {
  BytedanceLark = 1,
  OpenApi = 2,
}

export enum PlatStatus {
  Invalid = 0,
  /** 仅供查询使用, 包括Online和Offline */
  All = 1,
  Online = 2,
  Offline = 3,
}

export enum PermissionUserType {
  LarkEmail = 1,
  DepartmentID = 2,
  Invalid = 3,
}

export enum PermissionRoleType {
  SuperAdmin = 1,
  PlatAdmin = 11,
  SurveyCreator = 31,
  Visitor = 91,
  Non = 127,
}

export enum SurveyStatus {
  Invalid = 0,
  /** 仅在查询时出现, 包括下列三种 */
  All = 1,
  /** 未投放 */
  Offline = 10,
  /** 审批中 */
  Review = 20,
  /** 审批驳回 */
  Reject = 30,
  /** 预约投放 */
  Scheduled = 40,
  /** 正在投放 */
  Online = 50,
}

export enum SurveyListSortBy {
  DeliveryStartTime = 1,
  CreateTime = 2,
  SurveyStatus = 5,
  SurveyType = 6,
}

export enum LogicBoolType {
  And = 1,
  Or = 2,
}

export enum SurveyType {
  Invalid = 0,
  Embedded = 1,
  Independent = 2,
}

export enum EachUserConditionType {
  Trigger = 1,
  Sign = 2,
  TargetGroup = 3,
}

export enum LogicComparatorType {
  GT = 1,
  LT = 2,
  EQ = 3,
  GE = 4,
  LE = 5,
}

export enum LogicStringComparatorType {
  Eq = 1,
  NotEq = 2,
}

export enum LoginGroupComparatorType {
  In = 1,
  NotIn = 2,
}

/** 问题类型 */
export enum QuestionType {
  Invalid = 0,
  SingleChoice = 1,
  MultiChoice = 2,
  ShortAnswer = 3,
  Rating = 4,
  MultiChoiceTag = 5,
  Description = 6,
  NPS = 7,
  Matrix = 8,
}

export enum Device {
  Unknown = 1,
  IOS = 2,
  Android = 3,
  PC = 4,
}

export enum TargetGroupStatus {
  loading = 20,
  success = 30,
  failed = 40,
  delete = 90,
}

export enum ComplianceType {
  Invalid = 0,
  Tiktok = 1,
  Normal = 2,
}

export enum SignUsageDetailType {
  Survey = 1,
  TargetGroup = 2,
  SurveyGroup = 3,
}

export enum ParamType {
  SurveyName = 1,
}

export enum ShowType {
  /** 没有命中问卷 */
  None = 1,
  /** 嵌入式问卷返回完整内容 */
  Questionnaire = 2,
  /** 独立问卷嵌入式配置返回ID */
  Entry = 3,
}

export enum QuestionOrigin {
  URL = 1,
  Embedded = 2,
}


export interface LarkLoginInfo {
  loginEmail: string;
  openID: string;
}

export interface OpenApiInfo {
  platID: string;
}

export interface LarkUserInfo {
  loginEmail: string;
  userName: string;
  openID: string;
  userAvatar: string;
  employeeNumber: string;
  departmentName: string;
}

export interface DepartmentInfo {
  departmentName: string;
  departmentID: string;
}

export interface AccessUserInfo {
  accessUserType: AccessUserType;
  larkLoginInfo?: LarkLoginInfo;
  openApiInfo?: OpenApiInfo;
}

export interface IsSuperAdminReq {
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface IsSuperAdminResp {
  isSuper?: boolean;
  baseResp: base.BaseResp;
}

export interface CreatePlatReq {
  platName: string;
  platDes: string;
  /** 没有就传空 */
  logoURI?: string;
  accessUserInfo: AccessUserInfo;
  ownerList: Array<LarkLoginInfo>;
  compliance?: ComplianceType;
  base?: base.Base;
}

export interface CreatePlatResp {
  platID?: string;
  baseResp: base.BaseResp;
}

export interface GetPlatListReq {
  platStatus?: PlatStatus;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface UpdatePlatGeneralReq {
  platName: string;
  platDes: string;
  /** 没有就传空 */
  logoURI?: string;
  accessUserInfo: AccessUserInfo;
  ownerList: Array<LarkLoginInfo>;
  platID: string;
  base?: base.Base;
}

export interface UpdatePlatGeneralResp {
  baseResp: base.BaseResp;
}

/** platList接口专用 */
export interface PlatSimpleInfo {
  platID: string;
  platDes: string;
  platName: string;
  platLogo: string;
  createUser: LarkUserInfo;
  updateUser: LarkUserInfo;
  platStatus: PlatStatus;
  createTime: string;
  onlineSurveyCnt: string;
  totalSurveyCnt: string;
  ownerList: Array<LarkUserInfo>;
  updateTime: string;
  roleType: PermissionRoleType;
}

export interface StopPlatReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface StopPlatResp {
  baseResp: base.BaseResp;
}

export interface PublishPlatReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface PublishPlatResp {
  baseResp: base.BaseResp;
}

export interface DeletePlatReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface DeletePlatResp {
  baseResp: base.BaseResp;
}

export interface GetPlatListResp {
  total?: string;
  platList?: Array<PlatSimpleInfo>;
  baseResp: base.BaseResp;
}

export interface GetPlatDetailReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface PlatTriggerCondition {
  triggerName: string;
  key: string;
  triggerID: string;
  usage: number;
  updateTime: string;
  updateUser: LarkUserInfo;
}

export interface PlatSignCategory {
  name: string;
  definition: string;
}

export interface SignUsageDetail {
  detailType: SignUsageDetailType;
  surveyID?: string;
  targetGroupID?: string;
  surveyGroupPath?: string;
}

export interface PlatSign {
  signName: string;
  key: string;
  updateTime: string;
  signID: string;
  updateUser: LarkUserInfo;
  usage: number;
  categoryList: Array<PlatSignCategory>;
  isRequired: boolean;
  usageDetailList: Array<SignUsageDetail>;
}

export interface PlatPermission {
  userType: PermissionUserType;
  userInfo?: LarkUserInfo;
  departmentInfo?: DepartmentInfo;
  roleType: PermissionRoleType;
  permissionID: string;
}

export interface PlatDetailDomainInfo {
  domain: string;
  /** 问卷中用到的次数(被了删除的问卷以外的所有类型问卷) */
  usage: number;
  createTime: string;
  createUser: LarkUserInfo;
  domainID: string;
}

export interface PlatQps {
  sg: string;
  va: string;
  ttp: string;
  gcp: string;
  boe: string;
}

export interface PlatDetail {
  platID: string;
  platName: string;
  platDes: string;
  /** 没有则空串 */
  logoURI: string;
  ownerList: Array<LarkUserInfo>;
  domainList: Array<PlatDetailDomainInfo>;
  secretKey: string;
  triggerList: Array<PlatTriggerCondition>;
  signList: Array<PlatSign>;
  permissionList: Array<PlatPermission>;
  createUser: LarkUserInfo;
  updateUser: LarkUserInfo;
  platStatus: PlatStatus;
  createTime: string;
  updateTime: string;
  onlineSurveyCnt: string;
  totalSurveyCnt: string;
  entranceConfig: EntranceConfig;
  groupInfoList?: Array<TargetGroupInfo>;
  compliance?: ComplianceType;
  qpsLimit?: PlatQps;
}

export interface GetPlatDetailResp {
  platDetail?: PlatDetail;
  roleType?: PermissionRoleType;
  baseResp: base.BaseResp;
}

export interface UpdatePlatTriggerReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  triggerID: string;
  triggerName: string;
  base?: base.Base;
}

export interface UpdatePlatTriggerResp {
  baseResp: base.BaseResp;
}

export interface AddPlatSignReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  signName: string;
  signKey: string;
  isRequired: boolean;
  base?: base.Base;
}

export interface AddPlatSignResp {
  signID?: string;
  baseResp: base.BaseResp;
}

export interface UpdatePlatSignReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  signID: string;
  signName: string;
  isRequired: boolean;
  base?: base.Base;
}

export interface UpdatePlatSignResp {
  baseResp: base.BaseResp;
}

export interface DeletePlatSignReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  signID: string;
  base?: base.Base;
}

export interface DeletePlatSignResp {
  baseResp: base.BaseResp;
}

export interface AddPlatSignCategoryReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  signID: string;
  categoryName: string;
  definition: string;
  base?: base.Base;
}

export interface AddPlatSignCategoryResp {
  baseResp: base.BaseResp;
}

export interface DeletePlatSignCategoryReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  signID: string;
  categoryName: string;
  base?: base.Base;
}

export interface DeletePlatSignCategoryResp {
  baseResp: base.BaseResp;
}

export interface AddPlatPermissionReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  userType: PermissionUserType;
  userInfoList?: Array<LarkLoginInfo>;
  departmentInfoList?: Array<DepartmentInfo>;
  roleType: PermissionRoleType;
  base?: base.Base;
}

export interface AddPlatPermissionResp {
  permissionID?: Array<string>;
  baseResp: base.BaseResp;
}

export interface UpdatePlatPermissionReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  permissionID: string;
  roleType: PermissionRoleType;
  base?: base.Base;
}

export interface UpdatePlatPermissionResp {
  baseResp: base.BaseResp;
}

export interface DeletePlatPermissionReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  permissionID: string;
  base?: base.Base;
}

export interface DeletePlatPermissionResp {
  baseResp: base.BaseResp;
}

export interface AddPlatDomainReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  domain: string;
  base?: base.Base;
}

export interface AddPlatDomainResp {
  domainID?: string;
  baseResp: base.BaseResp;
}

export interface DeletePlatDomainReq {
  platID: string;
  domainID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface DeletePlatDomainResp {
  baseResp: base.BaseResp;
}

export interface AddPlatTriggerReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  triggerName: string;
  key: string;
  base?: base.Base;
}

export interface AddPlatTriggerResp {
  triggerID?: string;
  baseResp: base.BaseResp;
}

export interface DeletePlatTriggerReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  triggerID: string;
  base?: base.Base;
}

export interface DeletePlatTriggerResp {
  baseResp: base.BaseResp;
}

export interface EntranceConfig {
  enable: boolean;
  horizontally: string;
  vertically: string;
  pageLevel: string;
}

export interface UpdateEntranceConfigReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  entranceConfig: EntranceConfig;
  base?: base.Base;
}

export interface UpdateEntranceConfigResp {
  baseResp: base.BaseResp;
}

export interface GetSurveyListReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyStatus: SurveyStatus;
  page: number;
  limit: number;
  sortBy: SurveyListSortBy;
  isAsc: boolean;
  /** 模糊搜索 */
  searchKey: string;
  base?: base.Base;
}

export interface EachTranslationProgress {
  language: string;
  total: number;
  complete: number;
}

export interface SurveySimpleInfo {
  surveyID: string;
  surveyStatus: SurveyStatus;
  surveyName: string;
  deliveryStartTime: string;
  submitCnt: string;
  createUser: LarkUserInfo;
  updateUser: LarkUserInfo;
  translationProgress: Array<EachTranslationProgress>;
  surveyType: SurveyType;
  createTime: string;
  updateTime: string;
  starlingTaskUrl: string;
}

export interface GetSurveyListResp {
  surveyList?: Array<SurveySimpleInfo>;
  total?: string;
  baseResp: base.BaseResp;
}

export interface ShowConfig {
  /** 页面问题配置 */
  pageConfig: Array<EachPageConfig>;
  submitText: string;
  feedbackText: string;
}

export interface CreateOrUpdateSurveyReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  /** 没有即新建, 否则更新 */
  surveyID?: string;
  title: string;
  type: SurveyType;
  /** 调研出现等配置 */
  surveyConfig: SurveyConfig;
  /** 展示的问题等配置 */
  showConfig: ShowConfig;
  base?: base.Base;
}

/** 问卷页头部配置 */
export interface HeaderConfig {
  bannerURI?: string;
  title?: string;
  instructions?: string;
}

/** 随机序控制参数 */
export interface RandomizeOrderConfig {
  /** 作为ActionReportResp等对外接口的返回值会固定返回false, 由后端控制随机, 前端不管 */
  enable: boolean;
  /** 固定不参与随机的选项key */
  fixedOptionKey: Array<string>;
}

/** 选项互斥配置 */
export interface OptionConflictConfig {
  enable: boolean;
  /** 互斥的选项key */
  conflictOptionKey: Array<string>;
}

/** 展示条件 单项配置 */
export interface EachDisplayConditionConfig {
  questionKey: string;
  isSelected: boolean;
  logicType: LogicBoolType;
  optionKeyList: Array<string>;
}

/** 展示条件配置 */
export interface DisplayConditionConfig {
  enable: boolean;
  logicType: LogicBoolType;
  eachConfig: Array<EachDisplayConditionConfig>;
}

/** 选项选择后关联输入配置 */
export interface OptionRelationInputConfig {
  enable: boolean;
  isMust: boolean;
}

/** 单选题选项配置 */
export interface SingleChoiceOptionConfigData {
  optionKey: string;
  text: string;
  relationInput: OptionRelationInputConfig;
}

/** 单选题配置 */
export interface SingleChoiceData {
  isRequired: boolean;
  titleText: string;
  randomConfig: RandomizeOrderConfig;
  optionList: Array<SingleChoiceOptionConfigData>;
}

/** 多选题选项配置 */
export interface MultiChoiceOptionConfigData {
  optionKey: string;
  text: string;
  relationInput: OptionRelationInputConfig;
  conflictConfig: OptionConflictConfig;
}

/** 多选题配置 */
export interface MultiChoiceData {
  isRequired: boolean;
  titleText: string;
  randomConfig: RandomizeOrderConfig;
  /** 0 即不限制 */
  maxCnt: number;
  optionList: Array<MultiChoiceOptionConfigData>;
}

/** 简单题配置 */
export interface ShortAnswerData {
  isRequired: boolean;
  titleText: string;
  placeholderText: string;
}

/** 评分题选项配置 */
export interface RatingChoiceData {
  /** 分值 */
  score: string;
  text: string;
  optionKey: string;
}

/** 评分题配置 */
export interface RatingData {
  isRequired: boolean;
  titleText: string;
  /** 前端自样式定义 */
  style: number;
  optionList: Array<RatingChoiceData>;
}

/** 多选标签题 选项配置 */
export interface MultiChoiceTagOptionConfigData {
  optionKey: string;
  text: string;
}

/** 多选标签题配置 */
export interface MultiChoiceTagData {
  isRequired: boolean;
  titleText: string;
  randomConfig: RandomizeOrderConfig;
  /** 0 即不限制 */
  maxCnt: number;
  optionList: Array<MultiChoiceTagOptionConfigData>;
}

/** 问题描述配置 */
export interface DescriptionData {
  titleText: string;
}

export interface NPSChoiceOptionData {
  score: string;
  optionKey: string;
}

export interface NPSChoiceData {
  isRequired: boolean;
  s10Text: string;
  s0Text: string;
  optionList: Array<NPSChoiceOptionData>;
  titleText: string;
}

export interface MatrixData {
  isRequired: boolean;
  titleText: string;
  subOptionTitle: Array<string>;
  subQuestionList: Array<MatrixSubQeustionData>;
}

export interface MatrixSubQeustionData {
  titleText: string;
  optionKeyList: Array<string>;
}

/** 问题配置 */
export interface QuestionConfig {
  questionType: QuestionType;
  questionKey: string;
  displayCondition: DisplayConditionConfig;
  singleChoiceData?: SingleChoiceData;
  multiChoiceData?: MultiChoiceData;
  shortAnswerData?: ShortAnswerData;
  ratingData?: RatingData;
  multiChoiceTagData?: MultiChoiceTagData;
  descriptionData?: DescriptionData;
  npsChoiceData?: NPSChoiceData;
  matrixData?: MatrixData;
}

/** 每页配置 */
export interface EachPageConfig {
  headerConfig?: HeaderConfig;
  questionList: Array<QuestionConfig>;
}

/** 调研的入口设置 */
export interface SurveyEntranceConfig {
  /** 前端上传, 没有则留空 */
  styleURI: string;
  entranceConfig?: EntranceConfig;
  enable: boolean;
}

/** 展示时机 */
/** 类型:延迟进入页面 参数为  进入页面事件@view    + delay time > 0 */
/** 类型:默认              进入页面事件@view     + delay time = 0 */
/** 类型:用户行为           其他用户平台自定义事件 + delay time = 0 */
export interface AppearConditionConfig {
  /** 进入页面事件 */
  triggerKey: string;
  /** 秒 */
  delayTime: number;
}

/** 展示后展开时机 */
/** 类型:默认关闭       enable = false */
/** 类型:每xx天首次    enable = true , cycle=xx */
/** 类型:默认展开      enable = true, cycle=0 */
export interface PopupConditionConfig {
  /** false 默认不展开 */
  enable: boolean;
  /** 0 每次默认展开 */
  cycle: number;
}

/** 用户行为(触发事件配置) */
export interface EachUserConditionTypeTriggerParam {
  cycle: number;
  /** 循环周期 */
  triggerKey: string;
  /** 比较符 */
  comparator: LogicComparatorType;
  /** 比较右值 */
  rightValue: string;
  /** 判断频率的自定义标志 */
  signID: string;
}

/** 用户属性(自定义标志配置) */
export interface EachUserConditionTypeSignParam {
  /** 自定义标志ID */
  signID: string;
  /** 字符串逻辑操作类型 */
  logicType: LogicStringComparatorType;
  /** 右值, 直接传入category 的字符串 , 或用户自填值 */
  rightValue: Array<string>;
}

/** 人群包条件 */
export interface EachUserConditionTypeTargetGroupParam {
  /** 1: required list<i64>                       groupIDList; */
  logicType: LoginGroupComparatorType;
  groupID: string;
}

/** 单个用户限制条件 */
export interface EachUserCondition {
  /** 条件类型 */
  type: EachUserConditionType;
  paramConfig?: EachUserConditionTypeSignParam;
  triggerParam?: EachUserConditionTypeTriggerParam;
  groupParam?: EachUserConditionTypeTargetGroupParam;
}

/** 用户限制 */
export interface UserCondition {
  enable: boolean;
  logicType: LogicBoolType;
  conditionList: Array<EachUserCondition>;
}

export interface EachPathCondition {
  domainID: string;
  /** 路径  , 支持* 占位符 */
  path: string;
}

/** 域名路径限制 */
export interface PathCondition {
  /** false 的时候 默认只能由指定domain内请求, 不限制path */
  enable: boolean;
  conditionList?: Array<EachPathCondition>;
}

/** 定时配置 */
export interface TimingCondition {
  /** -1 表示不定时,  正数的时候标识有预期投放时间, 不能小于当前时间 */
  startTime: string;
  /** -1 表示不定时,  正数的时候标识有预期投放时间, 不能小于startTime */
  endTime: string;
  /** -1 表示不限制 */
  maxCnt: string;
  /** -1 表示不限制 */
  maxCntTTP: string;
  /** -1 表示不限制 */
  maxCntEuttp?: string;
}

/** 单个频率限制条件 */
export interface EachFrequencyCondition {
  timePeriod: number;
  /** @send 发放问卷(看见入口) */
  triggerKey: string;
  maxCnt: number;
  signID: string;
  enable: boolean;
}

/** 频率限制 */
export interface FrequencyCondition {
  enable: boolean;
  conditionList: Array<EachFrequencyCondition>;
}

/** 投放上限 */
export interface UpperLimitCondition {
  enable: boolean;
  signID: string;
  limit: string;
  ttpLimit: string;
  euttpLimit?: string;
}

/** 独立问卷提交配置 */
export interface IndependentSurveySetting {
  allowDuplicate: boolean;
}

/** 独立问卷嵌入配置 */
export interface IndependentSurveyEmbeddedConfig {
  enable: boolean;
  entranceConfig: SurveyEntranceConfig;
  appearConditionConfig: AppearConditionConfig;
  popupConditionConfig: PopupConditionConfig;
  userCondition: UserCondition;
  pathCondition: PathCondition;
  frequencyCondition: FrequencyCondition;
  upperLimitCondition: UpperLimitCondition;
}

/** 嵌入问卷嵌入配置 */
export interface EmbeddedSurveyEmbeddedConfig {
  entranceConfig: SurveyEntranceConfig;
  appearConditionConfig: AppearConditionConfig;
  popupConditionConfig: PopupConditionConfig;
  userCondition: UserCondition;
  pathCondition: PathCondition;
  frequencyCondition: FrequencyCondition;
  upperLimitCondition: UpperLimitCondition;
}

/** 通用配置 */
export interface CommonSurveyConfig {
  /** 没有配置多语言就传空吧 */
  langList: Array<string>;
  timingCondition: TimingCondition;
  theme: string;
  /** 业务方自定义字段 */
  customText?: string;
}

export interface CreateOrUpdateSurveyResp {
  surveyID?: string;
  baseResp: base.BaseResp;
}

export interface GetSurveyDetailReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  base?: base.Base;
}

export interface SurveyDetail {
  surveyID: string;
  title: string;
  type: SurveyType;
  surveyConfig: SurveyConfig;
  surveyStatus: SurveyStatus;
  showConfig: ShowConfig;
  platID: string;
}

export interface GetSurveyDetailResp {
  detail?: SurveyDetail;
  baseResp: base.BaseResp;
}

export interface CheckParamValidReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  paramType: ParamType;
  paramValue: CommonParam;
  base?: base.Base;
}

export interface CheckParamValidResp {
  baseResp: base.BaseResp;
}

export interface PublishSurveyReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  base?: base.Base;
}

export interface PublishSurveyResp {
  baseResp: base.BaseResp;
}

export interface TimingPublishSurveyReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  base?: base.Base;
}

export interface TimingPublishSurveyResp {
  baseResp: base.BaseResp;
}

export interface StopSurveyReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  base?: base.Base;
}

export interface StopSurveyResp {
  baseResp: base.BaseResp;
}

export interface TimingStopSurveyReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  base?: base.Base;
}

export interface TimingStopSurveyResp {
  baseResp: base.BaseResp;
}

export interface DeleteSurveyReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  base?: base.Base;
}

export interface DeleteSurveyResp {
  baseResp: base.BaseResp;
}

export interface ActionReportParam {
  /** 除了用户自定义的还有一些自定义的   , */
  triggerKey: string;
  eventTime: string;
  reportParam: ReportParam;
  /** 问卷相关事件需要上报  questionnaireID和submitID */
  questionnaireID?: string;
  submitID?: string;
}

export interface ActionReportReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  paramList: Array<ActionReportParam>;
  base?: base.Base;
}

export interface ShowEachPageConfig {
  headerConfig?: HeaderConfig;
  questionList: Array<QuestionConfig>;
}

export interface PreviewShowQuestionnaire {
  questionnaireID: string;
  entranceConfig?: SurveyEntranceConfig;
  showConfig: ShowConfig;
  surveyID: string;
  theme: string;
  defaultTranslation: { [key: string]: string };
  type: SurveyType;
  independentEntrance: boolean;
}

export interface ShowQuestionnaire {
  questionnaireID: string;
  isAutoPopup: boolean;
  entranceConfig: SurveyEntranceConfig;
  delay: string;
  showConfig: ShowConfig;
  /** 返回的问卷带有该ID, 后续每次问卷相关事件上报都必须携带此ID, 有效期2天 */
  submitID: string;
  surveyID: string;
  surveyVersion: number;
  theme?: string;
  defaultTranslation: { [key: string]: string };
  customText: string;
}

export interface ShowQuestionnaireEntry {
  questionnaireID: string;
  isAutoPopup: boolean;
  entranceConfig: SurveyEntranceConfig;
  delay: string;
  /** 返回的问卷带有该ID, 后续每次问卷相关事件上报都必须携带此ID, 有效期2天 */
  submitID: string;
  surveyID: string;
  surveyVersion: number;
  customText: string;
  theme: string;
  defaultTranslation: { [key: string]: string };
}

export interface ActionReportResp {
  /** 触发多个也只返回一个 */
  questionnaire?: ShowQuestionnaire;
  /** 触发多个也只返回一个 */
  entry?: ShowQuestionnaireEntry;
  showType: ShowType;
  baseResp: base.BaseResp;
}

export interface ShortAnswerAnswerData {
  answer: string;
}

export interface SingleChoiceAnswerData {
  optionKey: string;
  relationInput?: string;
}

export interface MultichoiceAnswerData {
  optionKey: string;
  relationInput?: string;
}

export interface MultiChoiceAnswerData {
  answerList: Array<MultichoiceAnswerData>;
}

export interface MultiChoiceTagAnswerData {
  optionKeyList: Array<string>;
}

export interface RatingAnswerData {
  optionKey: string;
}

export interface NpsAnswerData {
  optionKey: string;
}

export interface MatrixAnswerData {
  optionKey: Array<string>;
}

export interface QuestionAnswer {
  questionKey: string;
  questionType: QuestionType;
  answerData: AnswerData;
  /** 问题是显示的第几个 */
  showIndex: number;
}

export interface GetPlatInfoTrimReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface SignInfoTrim {
  key: string;
  isRequired: boolean;
}

export interface PlatDetailTrim {
  platID: string;
  secretKey: string;
  domainList: Array<string>;
  triggerKeyList: Array<string>;
  signList: Array<SignInfoTrim>;
}

export interface GetPlatInfoTrimResp {
  platDetail?: PlatDetailTrim;
  baseResp: base.BaseResp;
}

export interface ReportParam {
  /** 用户自定义标识 */
  sign: { [key: string]: string };
  srcIP: string;
  userAgent: string;
  referUrl: string;
  device: Device;
  /** ISO 639-1 标准 */
  language: string;
  /** 问卷相关需要上报 */
  questionOrigin?: QuestionOrigin;
  deviceID: string;
}

export interface SubmitAnswerReq {
  questionnaireID: string;
  eventTime: string;
  reportParam: ReportParam;
  /** 毫秒 */
  submitCost: number;
  accessUserInfo: AccessUserInfo;
  answerData: Array<QuestionAnswer>;
  platID: string;
  /** 返回问卷时携带该ID */
  submitID: string;
  base?: base.Base;
}

export interface SubmitAnswerResp {
  submitID?: string;
  baseResp: base.BaseResp;
}

export interface SurveyConfig {
  /** 独立问卷嵌入式配置 */
  independentEmbeddedSurveyConfig?: IndependentSurveyEmbeddedConfig;
  /** 嵌入式问卷嵌入式配置 */
  embeddedSurveyConfig?: EmbeddedSurveyEmbeddedConfig;
  /** 问卷通用配置 */
  commonSurveyConfig: CommonSurveyConfig;
  /** 独立问卷提交配置 */
  independentSurveySetting?: IndependentSurveySetting;
}

export interface GetSurveyMultiLangReq {
  surveyID: string;
  platID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface GetSurveyMultiLangResp {
  multiLangMap?: { [key: string]: PreviewShowQuestionnaire };
  translationProgress?: Array<EachTranslationProgress>;
  baseResp: base.BaseResp;
}

export interface GetSurveyGroupConfigReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface SurveyGroup {
  /** 空串表示不限制 */
  path: string;
  surveyList: Array<SurveyGroupItem>;
  frequencyCondition?: FrequencyCondition;
  groupName: string;
}

export interface SurveyGroupItem {
  surveyID: string;
  surveyTitle: string;
  weight: number;
  surveyStatus: SurveyStatus;
}

export interface GetSurveyGroupConfigResp {
  groupConfigList: Array<SurveyGroup>;
  baseResp: base.BaseResp;
}

export interface UpdateSurveyGroupConfigReq {
  platID: string;
  groupConfig: Array<SurveyGroup>;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface UpdateSurveyGroupConfigResp {
  baseResp: base.BaseResp;
}

export interface TargetGroupInfo {
  groupID: string;
  groupName: string;
  createUser: LarkUserInfo;
  createTime: string;
  usedSurveyList: Array<string>;
  fileKey: string;
  status: TargetGroupStatus;
  signID: string;
  containNum: string;
}

export interface AddTargetGroupReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  groupName: string;
  fileKey: string;
  signID: string;
  base?: base.Base;
}

export interface AddTargetGroupResp {
  groupID: string;
  baseResp: base.BaseResp;
}

export interface DeleteTargetGroupReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  groupID: string;
  base?: base.Base;
}

export interface DeleteTargetGroupResp {
  baseResp: base.BaseResp;
}

export interface GetSurveyChangeLogReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  /** default 1 */
  page?: number;
  /** default 20 */
  limit?: number;
  base?: base.Base;
}

export interface SurveyChangeLog {
  /** 传空表示系统 */
  optUser?: LarkUserInfo;
  optDetail: string;
  optTime: string;
}

export interface GetSurveyChangeLogResp {
  logList: Array<SurveyChangeLog>;
  baseResp: base.BaseResp;
}

export interface GetSurveyStatisticDataReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  base?: base.Base;
}

export interface SurveyVersion {
  version: number;
  onlineTime: string;
  offlineTime: string;
  questionnaireID: string;
}

export interface SurveyStatisticData {
  surveyID: string;
  surveyTitle: string;
  surveyVersionList: Array<SurveyVersion>;
  signList: Array<PlatSign>;
  surveyStatus: SurveyStatus;
  surveyType: SurveyType;
  compliance: ComplianceType;
}

export interface GetSurveyStatisticDataResp {
  data?: SurveyStatisticData;
  baseResp: base.BaseResp;
}

export interface SurveyQuestionnaireData {
  enQuestinnaire?: ShowQuestionnaire;
  oriQuestionnaire: ShowQuestionnaire;
  surveyID: string;
  version: number;
  questionnaireID: string;
  hasTTPData: boolean;
  zhQuestinnaire?: ShowQuestionnaire;
  hasMultiLang: boolean;
}

export interface GetSurveyQuestionnaireDataReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  version: number;
  questionnaireID: string;
  base?: base.Base;
}

export interface GetSurveyQuestionnaireDataResp {
  data?: SurveyQuestionnaireData;
  baseResp: base.BaseResp;
}

export interface UpdatePlatQPSLimitReq {
  platID: string;
  qpsLimit: PlatQps;
  accessUserInfo: AccessUserInfo;
  base?: base.Base;
}

export interface UpdatePlatQPSLimitResp {
  baseResp: base.BaseResp;
}

export interface GetIndependentQuestionnaireReq {
  submitID?: string;
  reportParam: ReportParam;
  surveyID: string;
  base?: base.Base;
}

export interface IndependentQuestionnaireData {
  /** 返回的问卷带有该ID, 后续每次问卷相关事件上报都必须携带此ID, 有效期7天 */
  submitID: string;
  questionnaireID: string;
  surveyID: string;
  surveyVersion: number;
  showConfig: ShowConfig;
  /** 没有则为空; */
  langList: Array<string>;
  platID: string;
  theme: string;
  allowDuplicate: boolean;
  customText: string;
}

export interface CopySurveyReq {
  platID: string;
  accessUserInfo: AccessUserInfo;
  surveyID: string;
  targetType?: SurveyType;
  base?: base.Base;
}

export interface CopySurveyResp {
  baseResp: base.BaseResp;
}

export interface GetIndependentQuestionnaireResp {
  data?: IndependentQuestionnaireData;
  defaultTranslation: { [key: string]: string };
  errorCode?: string;
  baseResp: base.BaseResp;
}

export interface CommonParam {
  stringValue?: string;
  numValue?: string;
}

export interface QuestionConfigData {
  singleChoiceData?: SingleChoiceData;
  multiChoiceData?: MultiChoiceData;
  shortAnswerData?: ShortAnswerData;
  ratingData?: RatingData;
  multiChoiceTagData?: MultiChoiceTagData;
}

export interface AnswerData {
  singleChoiceAnswerData?: SingleChoiceAnswerData;
  shortAnswerDataAnswerData?: ShortAnswerAnswerData;
  multiChoiceAnswerData?: MultiChoiceAnswerData;
  multiChoiceTagAnswerData?: MultiChoiceTagAnswerData;
  ratingAnswerData?: RatingAnswerData;
  npsAnswerData?: NpsAnswerData;
  matrixAnswerData?: MatrixAnswerData;
}


export declare class FeelgoodWebService {
  /** 管理端: 判断是否为超管 */
  public IsSuperAdmin(req: IsSuperAdminReq): Promise<IsSuperAdminResp>;
  public IsSuperAdmin(ctx: ClientInvokeOptions, req: IsSuperAdminReq): Promise<IsSuperAdminResp>;

  /** 管理端: 新建平台 */
  public CreatePlat(req: CreatePlatReq): Promise<CreatePlatResp>;
  public CreatePlat(ctx: ClientInvokeOptions, req: CreatePlatReq): Promise<CreatePlatResp>;

  /** 管理端: 获取平台列表 */
  public GetPlatList(req: GetPlatListReq): Promise<GetPlatListResp>;
  public GetPlatList(ctx: ClientInvokeOptions, req: GetPlatListReq): Promise<GetPlatListResp>;

  /** 管理端: 停用平台 */
  public StopPlat(req: StopPlatReq): Promise<StopPlatResp>;
  public StopPlat(ctx: ClientInvokeOptions, req: StopPlatReq): Promise<StopPlatResp>;

  /** 管理端: 启用平台 */
  public PublishPlat(req: PublishPlatReq): Promise<PublishPlatResp>;
  public PublishPlat(ctx: ClientInvokeOptions, req: PublishPlatReq): Promise<PublishPlatResp>;

  /** 管理端: 删除平台 */
  public DeletePlat(req: DeletePlatReq): Promise<DeletePlatResp>;
  public DeletePlat(ctx: ClientInvokeOptions, req: DeletePlatReq): Promise<DeletePlatResp>;

  /** 管理端: 修改平台基本设置 */
  public UpdatePlatGeneral(req: UpdatePlatGeneralReq): Promise<UpdatePlatGeneralResp>;
  public UpdatePlatGeneral(ctx: ClientInvokeOptions, req: UpdatePlatGeneralReq): Promise<UpdatePlatGeneralResp>;

  /** 管理端: 获取平台详情 */
  public GetPlatDetail(req: GetPlatDetailReq): Promise<GetPlatDetailResp>;
  public GetPlatDetail(ctx: ClientInvokeOptions, req: GetPlatDetailReq): Promise<GetPlatDetailResp>;

  /** 管理端: 新增平台配置路径 */
  public AddPlatDomain(req: AddPlatDomainReq): Promise<AddPlatDomainResp>;
  public AddPlatDomain(ctx: ClientInvokeOptions, req: AddPlatDomainReq): Promise<AddPlatDomainResp>;

  /** 管理端: 删除平台配置路径 */
  public DeletePlatDomain(req: DeletePlatDomainReq): Promise<DeletePlatDomainResp>;
  public DeletePlatDomain(ctx: ClientInvokeOptions, req: DeletePlatDomainReq): Promise<DeletePlatDomainResp>;

  /** 管理端: 新增平台触发事件 */
  public AddPlatTrigger(req: AddPlatTriggerReq): Promise<AddPlatTriggerResp>;
  public AddPlatTrigger(ctx: ClientInvokeOptions, req: AddPlatTriggerReq): Promise<AddPlatTriggerResp>;

  /** 管理端: 修改平台触发事件 */
  public UpdatePlatTrigger(req: UpdatePlatTriggerReq): Promise<UpdatePlatTriggerResp>;
  public UpdatePlatTrigger(ctx: ClientInvokeOptions, req: UpdatePlatTriggerReq): Promise<UpdatePlatTriggerResp>;

  /** 管理端: 删除平台触发事件 */
  public DeletePlatTrigger(req: DeletePlatTriggerReq): Promise<DeletePlatTriggerResp>;
  public DeletePlatTrigger(ctx: ClientInvokeOptions, req: DeletePlatTriggerReq): Promise<DeletePlatTriggerResp>;

  /** 管理端: 新增平台自定义标志分类 */
  public AddPlatSign(req: AddPlatSignReq): Promise<AddPlatSignResp>;
  public AddPlatSign(ctx: ClientInvokeOptions, req: AddPlatSignReq): Promise<AddPlatSignResp>;

  /** 管理端: 修改平台自定义标志分类 */
  public UpdatePlatSign(req: UpdatePlatSignReq): Promise<UpdatePlatSignResp>;
  public UpdatePlatSign(ctx: ClientInvokeOptions, req: UpdatePlatSignReq): Promise<UpdatePlatSignResp>;

  /** 管理端: 删除平台自定义标志 */
  public DeletePlatSign(req: DeletePlatSignReq): Promise<DeletePlatSignResp>;
  public DeletePlatSign(ctx: ClientInvokeOptions, req: DeletePlatSignReq): Promise<DeletePlatSignResp>;

  /** 管理端: 新增平台自定义标志分类 */
  public AddPlatSignCategory(req: AddPlatSignCategoryReq): Promise<AddPlatSignCategoryResp>;
  public AddPlatSignCategory(ctx: ClientInvokeOptions, req: AddPlatSignCategoryReq): Promise<AddPlatSignCategoryResp>;

  /** 管理端: 删除平台自定义标志分类 */
  public DeletePlatSignCategory(req: DeletePlatSignCategoryReq): Promise<DeletePlatSignCategoryResp>;
  public DeletePlatSignCategory(ctx: ClientInvokeOptions, req: DeletePlatSignCategoryReq): Promise<DeletePlatSignCategoryResp>;

  /** 管理端: 添加单个用户/部门平台权限 */
  public AddPlatPermission(req: AddPlatPermissionReq): Promise<AddPlatPermissionResp>;
  public AddPlatPermission(ctx: ClientInvokeOptions, req: AddPlatPermissionReq): Promise<AddPlatPermissionResp>;

  /** 管理端: 修改单个用户/部门平台权限 */
  public UpdatePlatPermission(req: UpdatePlatPermissionReq): Promise<UpdatePlatPermissionResp>;
  public UpdatePlatPermission(ctx: ClientInvokeOptions, req: UpdatePlatPermissionReq): Promise<UpdatePlatPermissionResp>;

  /** 管理端: 删除单个用户/部门平台权限 */
  public DeletePlatPermission(req: DeletePlatPermissionReq): Promise<DeletePlatPermissionResp>;
  public DeletePlatPermission(ctx: ClientInvokeOptions, req: DeletePlatPermissionReq): Promise<DeletePlatPermissionResp>;

  /** 管理端: 更新平台统一的问卷入口配置 */
  public UpdateEntranceConfig(req: UpdateEntranceConfigReq): Promise<UpdateEntranceConfigResp>;
  public UpdateEntranceConfig(ctx: ClientInvokeOptions, req: UpdateEntranceConfigReq): Promise<UpdateEntranceConfigResp>;

  /** 管理端: 获取问卷列表 */
  public GetSurveyList(req: GetSurveyListReq): Promise<GetSurveyListResp>;
  public GetSurveyList(ctx: ClientInvokeOptions, req: GetSurveyListReq): Promise<GetSurveyListResp>;

  /** 管理端: 创建/更新问卷 */
  public CreateOrUpdateSurvey(req: CreateOrUpdateSurveyReq): Promise<CreateOrUpdateSurveyResp>;
  public CreateOrUpdateSurvey(ctx: ClientInvokeOptions, req: CreateOrUpdateSurveyReq): Promise<CreateOrUpdateSurveyResp>;

  /** 管理端: 获取问卷配置详情 */
  public GetSurveyDetail(req: GetSurveyDetailReq): Promise<GetSurveyDetailResp>;
  public GetSurveyDetail(ctx: ClientInvokeOptions, req: GetSurveyDetailReq): Promise<GetSurveyDetailResp>;

  /** 管理端: 获取问卷多语言预览信息 */
  public GetSurveyMultiLang(req: GetSurveyMultiLangReq): Promise<GetSurveyMultiLangResp>;
  public GetSurveyMultiLang(ctx: ClientInvokeOptions, req: GetSurveyMultiLangReq): Promise<GetSurveyMultiLangResp>;

  public CheckParamValid(req: CheckParamValidReq): Promise<CheckParamValidResp>;
  public CheckParamValid(ctx: ClientInvokeOptions, req: CheckParamValidReq): Promise<CheckParamValidResp>;

  /** 管理端: 开始投放问卷 */
  public PublishSurvey(req: PublishSurveyReq): Promise<PublishSurveyResp>;
  public PublishSurvey(ctx: ClientInvokeOptions, req: PublishSurveyReq): Promise<PublishSurveyResp>;

  /** 管理端: 定时服务专用,开始投放问卷 */
  public TimingPublishSurvey(req: TimingPublishSurveyReq): Promise<TimingPublishSurveyResp>;
  public TimingPublishSurvey(ctx: ClientInvokeOptions, req: TimingPublishSurveyReq): Promise<TimingPublishSurveyResp>;

  /** 管理端: 停止投放问卷 */
  public StopSurvey(req: StopSurveyReq): Promise<StopSurveyResp>;
  public StopSurvey(ctx: ClientInvokeOptions, req: StopSurveyReq): Promise<StopSurveyResp>;

  /** 管理端: 定时服务专用,停止投放问卷 */
  public TimingStopSurvey(req: TimingStopSurveyReq): Promise<TimingStopSurveyResp>;
  public TimingStopSurvey(ctx: ClientInvokeOptions, req: TimingStopSurveyReq): Promise<TimingStopSurveyResp>;

  /** 管理端: 删除问卷 */
  public DeleteSurvey(req: DeleteSurveyReq): Promise<DeleteSurveyResp>;
  public DeleteSurvey(ctx: ClientInvokeOptions, req: DeleteSurveyReq): Promise<DeleteSurveyResp>;

  /** 管理端: 获取问卷组配置 */
  public GetSurveyGroupConfig(req: GetSurveyGroupConfigReq): Promise<GetSurveyGroupConfigResp>;
  public GetSurveyGroupConfig(ctx: ClientInvokeOptions, req: GetSurveyGroupConfigReq): Promise<GetSurveyGroupConfigResp>;

  /** 管理端: 更新问卷组配置 */
  public UpdateSurveyGroupConfig(req: UpdateSurveyGroupConfigReq): Promise<UpdateSurveyGroupConfigResp>;
  public UpdateSurveyGroupConfig(ctx: ClientInvokeOptions, req: UpdateSurveyGroupConfigReq): Promise<UpdateSurveyGroupConfigResp>;

  /** 管理端: 删除人群包 */
  public DeleteTargetGroup(req: DeleteTargetGroupReq): Promise<DeleteTargetGroupResp>;
  public DeleteTargetGroup(ctx: ClientInvokeOptions, req: DeleteTargetGroupReq): Promise<DeleteTargetGroupResp>;

  /** 管理端: 增加人群包 */
  public AddTargetGroup(req: AddTargetGroupReq): Promise<AddTargetGroupResp>;
  public AddTargetGroup(ctx: ClientInvokeOptions, req: AddTargetGroupReq): Promise<AddTargetGroupResp>;

  /** 管理端: 获取问卷变更日志 */
  public GetSurveyChangeLog(req: GetSurveyChangeLogReq): Promise<GetSurveyChangeLogResp>;
  public GetSurveyChangeLog(ctx: ClientInvokeOptions, req: GetSurveyChangeLogReq): Promise<GetSurveyChangeLogResp>;

  /** 管理端: 人群包回调 */
  public TargetGroupCallback(req: target_group_callback.TargetGroupCallbackReq): Promise<target_group_callback.TargetGroupCallbackResp>;
  public TargetGroupCallback(ctx: ClientInvokeOptions, req: target_group_callback.TargetGroupCallbackReq): Promise<target_group_callback.TargetGroupCallbackResp>;

  /** 管理端:获取问卷问卷统计数据 */
  public GetSurveyStatisticData(req: GetSurveyStatisticDataReq): Promise<GetSurveyStatisticDataResp>;
  public GetSurveyStatisticData(ctx: ClientInvokeOptions, req: GetSurveyStatisticDataReq): Promise<GetSurveyStatisticDataResp>;

  /** 管理端:获取问卷(指定版本)详情数据 */
  public GetSurveyQuestionnaireData(req: GetSurveyQuestionnaireDataReq): Promise<GetSurveyQuestionnaireDataResp>;
  public GetSurveyQuestionnaireData(ctx: ClientInvokeOptions, req: GetSurveyQuestionnaireDataReq): Promise<GetSurveyQuestionnaireDataResp>;

  /** 管理端:更新专区QPS限制 */
  public UpdatePlatQPSLimit(req: UpdatePlatQPSLimitReq): Promise<UpdatePlatQPSLimitResp>;
  public UpdatePlatQPSLimit(ctx: ClientInvokeOptions, req: UpdatePlatQPSLimitReq): Promise<UpdatePlatQPSLimitResp>;

  /** 管理端: 复制问卷 */
  public CopySurvey(req: CopySurveyReq): Promise<CopySurveyResp>;
  public CopySurvey(ctx: ClientInvokeOptions, req: CopySurveyReq): Promise<CopySurveyResp>;

  /** 投放端: 获取平台简要信息 */
  public GetPlatInfoTrim(req: GetPlatInfoTrimReq): Promise<GetPlatInfoTrimResp>;
  public GetPlatInfoTrim(ctx: ClientInvokeOptions, req: GetPlatInfoTrimReq): Promise<GetPlatInfoTrimResp>;

  /** 投放端: 事件上报 */
  public ActionReport(req: ActionReportReq): Promise<ActionReportResp>;
  public ActionReport(ctx: ClientInvokeOptions, req: ActionReportReq): Promise<ActionReportResp>;

  /** 投放端: 提交答案 */
  public SubmitAnswer(req: SubmitAnswerReq): Promise<SubmitAnswerResp>;
  public SubmitAnswer(ctx: ClientInvokeOptions, req: SubmitAnswerReq): Promise<SubmitAnswerResp>;

  /** 投放端:获取独立问卷内容 */
  public GetIndependentQuestionnaire(req: GetIndependentQuestionnaireReq): Promise<GetIndependentQuestionnaireResp>;
  public GetIndependentQuestionnaire(ctx: ClientInvokeOptions, req: GetIndependentQuestionnaireReq): Promise<GetIndependentQuestionnaireResp>;

}
