import {
  Device,
  ShowQuestionnaire,
  ShowQuestionnaireEntry,
} from '@byted-feelgood/components';
/**
 * token type
 */
export interface IAuthToken {
  tokenType: string;
  token: string;
  expiresIn?: number;
}
/**
 * feelgood入口参数
 */
export interface IFeelgoodConfig {
  [key: string]: any;
  /**
   * 请求地址
   * 内网
   * sg:  feelgood-deliverer.gf-sg.byteintl.net
   * va:  feelgood-deliverer.gf-us.byteintl.net
   * gcp: feelgood-deliverer.gf-useast-red.byteintl.net
   * ttp(usts): feelgood-deliverer.gf.tiktok-usts.net
   * 外网
   * row: www.tiktok.com(基于请求IP就近访问row内机房)
   * sg:  web-sg.tiktok.com
   * va:  web-va.tiktok.com
   * gcp: web-i18n.tiktok.com
   * ttp: us.tiktok.com
   */
  host:
    | 'www.tiktok.com'
    | 'web-sg.tiktok.com'
    | 'web-va.tiktok.com'
    | 'web-i18n.tiktok.com'
    | 'us.tiktok.com'
    | 'feelgood-deliverer.gf-sg.byteintl.net'
    | 'feelgood-deliverer.gf-us.byteintl.net'
    | 'feelgood-deliverer.gf-useast-red.byteintl.net'
    | 'feelgood-deliverer.gf.tiktok-usts.net';
  /**
   * 获取token的函数，接入方实现，
   */
  getAuthToken?: (options?: any) => Promise<IAuthToken>;
  /**
   * 手动传入 token，接入方维护更新机制
   */
  authToken?: IAuthToken;
  /**
   * 专区ID
   */
  platformID: string;
  /**
   * 语言
   */
  language: string;
  /**
   * 自定义标识
   */
  sign?: Record<string, string>;
  /**
   * 渲染的容器DOM节点
   * 传入节点后将直接渲染问卷到该节点，无入口相关逻辑，无延时相关逻辑
   */
  container?: HTMLElement;
  /**
   * 问卷相关设置
   */
  questionnaire?: IEmbeddedQuestionnaireOptions;
  /**
   * 问卷数据，通过open api获取数据传入可直接渲染问卷，不启动监听流程
   */
  questionnaireData?: ShowQuestionnaire;
  /**
   * 默认创建实例时同步开始路由监听，改成false后需手动调用startListener
   * @defaults true
   */
  autoStartListen?: boolean;
  /**
   * 主动设定问卷页面路径, 不设置会从页面自动获取当前路径
   */
  deliverPath?: string;
}

/**
 * trigger event 参数
 */
export interface ITriggerEvent {
  /**
   * 渲染的容器DOM节点
   * 传入节点后，无入口相关逻辑，无延时相关逻辑
   */
  container?: HTMLElement;
  /**
   * 是否空跑，只返回问卷，不渲染
   * @default false
   */
  dryrun?: boolean;
  /**
   * 自定义标识，传入则会覆盖 初始化传入的 sign
   */
  sign?: Record<string, string>;
  /**
   * 问卷相关设置
   */
  questionnaire?: IEmbeddedQuestionnaireOptions;
}

export enum ClosePosEnum {
  Default = 1,
  Entry = 2,
  Mask = 3,
}
/**
 * 嵌入式问卷配置
 */
export interface IEmbeddedQuestionnaireOptions {
  /**
   * 白天/黑夜模式
   * @defaults light
   */
  mode?: 'light' | 'dark';
  /**
   * 主题json
   * 无黑夜模式的主题，兜底白天主题
   */
  theme?: ThemeType;
  /**
   * 问卷位置、层级、宽高的样式，
   */
  style?: IStyleType;
  /**
   * 设备
   */
  device?: Device;
  /**
   * 入口图标
   */
  entryUrl?: string;
  /**
   * 是否沉默执行，即提交接口报错也在用户侧界面显示成功
   * @defaults true
   */
  silence?: boolean;
  /**
   * 是否显示，提交成功后的反馈页面
   * @defaults true
   */
  feedback?: boolean;
  /**
   * 是否显示关闭按钮
   * @defaults true
   */
  showCloseButton?: boolean;
  /**
   * 是否自动滚动问卷
   * @defaults false
   */
  autoScroll?: boolean;
  /**
   * 移动端是否有蒙层，默认false
   */
  mobileHasMask?: boolean;
  /**
   * 问卷预填数据，使用方式查看文档
   * https://bytedance.feishu.cn/docx/PfmVdTMBvofzwWxz1m3czxifnsp
   */
  prefillData?: Record<string, any>;
  /**
   * 当输入框获取焦点时，包括 input \ textarea
   */
  onInputFocus?: (event: FocusEvent) => any;
  /**
   * 当输入框失去焦点时，包括 input \ textarea
   */
  onInputBlur?: (event: FocusEvent) => any;
  /**
   * 点击了右上角,入口，蒙层时，触发问卷关闭回调
   * closePos触发位置 1：右上角X，2：入口，3：蒙层
   * trueClose问卷是否真正卸载。存在入口时关闭问卷只会最小化，该参数返回false
   */
  onClose?: (closePos: ClosePosEnum, trueClose?: boolean) => any;
  /**
   * 问卷提交时
   */
  onSubmitStart?: (values: any) => any;
  /**
   * 问卷提交失败时
   */
  onSubmitFailed?: () => any;
  /**
   * 问卷提交成功时
   */
  onSubmitSuccess?: () => any;
  /**
   * 问卷提交过程结束，不论成功或失败都会触发
   */
  onSubmitEnd?: () => any;
  /**
   * 当问卷展示时
   */
  onSurveyShow?: () => any;
  /**
   * 问卷挂载完成时
   * 如果返回 `false` ，则不上报 问卷内容展示 事件，须业务方手动上报
   */
  onMounted?: (
    params: ShowQuestionnaire | ShowQuestionnaireEntry,
  ) => Promise<boolean>;
  /**
   * 问卷卸载完成时
   */
  onUnmounted?: () => any;
  /**
   * 问卷卸载前
   * 如果返回 `false` ，阻止卸载,须手动调用unmount
   */
  onBeforeUnmounted?: () => Promise<boolean>;
}
/**
 * 入口样式配置
 */
export interface IStyleType {
  /**
   * 位置和层级
   *  right 默认32px
   *  bottom 默认40px
   *  z-index 默认body fixed 10000
   *  zIndexMask 移动端蒙层，fixed 9999
   *  MaskColor 移动端蒙层颜色 rgba(0, 0, 0, 0.4);
   */
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  zIndex?: number;
  zIndexMask?: number;
  MaskColor?: string;

  /**
   * 窗口宽度，默认pc：400px，mobile：100%
   */
  width?: string;
  /**
   * 可滚动区域高度，默认350px
   */
  maxHeight?: string;
  /**
   * 问卷弹出方向,默认left
   */
  popDirection?: 'left' | 'right';
}
/**
 * 主题配置
 */
export interface ThemeType {
  themeId?: string;
  // 分设备和明暗传入主题对应属性
  global?: {
    light: Record<string, string>;
    dark: Record<string, string>;
    desktop: Record<string, string>;
    mobile: Record<string, string>;
    [key: string]: Record<string, string>;
  };
  // 按题型覆盖样式，目前仅支持textarea
  question?: {
    singleChoice?: Record<string, string>;
    multiChoice?: Record<string, string>;
    multiChoiceTag?: Record<string, string>;
    npsChoice?: Record<string, string>;
    rating?: Record<string, string>;
    shortAnswer?: Record<string, string>;
    textarea?: Record<string, string>;
  };
}
/**
 * 问卷实例
 */
export interface IEmbeddedQuestionnaireRef {
  /**
   * 主动卸载问卷组件
   */
  unmount?: () => any;
  /**
   * 问卷数据
   */
  questionnaireData?: ShowQuestionnaire | ShowQuestionnaireEntry;

  [key: string]: any;
}

/**
 * 系统默认触发事件事件
 */
export enum EDefaultTriggerKey {
  /**
   * 进入页面 @view
   */
  View = '@view',
  /**
   * 下发问卷 @send
   */
  Send = '@send',
  /**
   * 入口曝光 @entry_show
   */
  EntryShow = '@entry_show',
  /**
   * 入口点击 @entry_click
   */
  EntryClick = '@entry_click',
  /**
   * 关闭按钮点击(非拒答) @close_click
   */
  CloseClick = '@close_click',
  /**
   * 点击拒绝 @reject
   */
  Reject = '@reject',
  /**
   * 问卷内容曝光 @show
   */
  Show = '@show',
  /**
   * 填写问卷 @answer
   */
  Answer = '@answer',
  /**
   * 提交问卷 @submit
   */
  Submit = '@submit',
}
