import { FeelgoodOne } from '@icon-park/react';
import { useBoolean } from '@byted/hooks';
import classNames from 'classnames';
import { useRef, useEffect, useState } from 'react';
import { Tooltip } from '@okee-uikit/react';
import { IQuestionnaireProps } from '../questionnaire/questionnaire';
import ComponentStore from '../../store';
import styles from './style.module.less';
import '../../feelgood.less';

const Entry: React.FC<IQuestionnaireProps> = props => {
  const {
    mode = 'light',
    theme,
    style = {},
    entryUrl,
    device,
    defaultTextMap,
    onEntryClick,
    onEntryShow,
    isAutoPopup,
    preview,
  } = props;
  const showEntry = useBoolean(true);
  const [showTip, setShowTip] = useState<boolean | undefined>();
  const { getCurrentDevice, setThemeData, defaultText, setDefaultText } =
    ComponentStore.useContext();
  useEffect(() => {
    setDefaultText(defaultTextMap);
  }, [defaultTextMap]);
  const feelgoodRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isAutoPopup || preview) {
      setShowTip(true);
      setTimeout(() => {
        setShowTip(undefined);
      }, 3000);
    }
  }, [isAutoPopup]);
  useEffect(() => {
    if (theme && mode && feelgoodRef.current) {
      setThemeData(theme, mode, getCurrentDevice(device), feelgoodRef.current);
    }
  }, [theme, mode, device, feelgoodRef]);

  useEffect(() => {
    if (showEntry.state) {
      onEntryShow?.();
    }
  }, [onEntryShow, showEntry.state]);
  return (
    <div
      className={classNames(
        'feelgood',
        `feelgood__theme--${mode}`,
        `feelgood__device--${getCurrentDevice(device)}`,
        styles[`questionnaire__widgets-${getCurrentDevice(device)}`],
      )}
      id="feelgood-questionnaire-widgets"
      ref={feelgoodRef}
      style={{
        visibility: 'hidden',
        left: style?.left,
        top: style?.top,
        right: style?.right,
        bottom: style?.bottom,
        zIndex: style?.zIndex,
        flexDirection: style?.popDirection === 'right' ? 'row-reverse' : 'row',
      }}
    >
      <Tooltip
        visible={showTip}
        content={
          defaultText.go_to_independent_page ||
          'Go to a independent page to complete the survey'
        }
        placement="left"
        showDelay={300}
        mode="dark"
        size={'sm'}
        offset={{ x: 0, y: -5 }}
        hideToDestroy
      >
        <div
          className={classNames(styles.questionnaire__entry, styles.entry)}
          style={{
            backgroundColor: entryUrl ? 'transparent' : '',
            boxShadow: entryUrl ? 'unset' : '',
          }}
          onClick={() => {
            onEntryClick?.(showEntry.state);
          }}
        >
          {entryUrl ? (
            <img
              src={entryUrl}
              className={styles.customImg}
              alt=""
              onError={() => {
                console.warn('img error');
              }}
            />
          ) : (
            <FeelgoodOne theme="outline" size="24" fill="#ffffff" />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default function EntryMain(prop: IQuestionnaireProps) {
  return (
    <ComponentStore.Provider>
      <Entry {...prop} />
    </ComponentStore.Provider>
  );
}
