export * from './device';
export { default as memorize } from './memorize';
export function isInPage(node: Node | null) {
  return node === document.body ? false : document.body.contains(node);
}

let intersectionObserver: IntersectionObserver;
export function startVisibleListen(
  el: Element | null,
  cb: (params: IntersectionObserverEntry[]) => void,
) {
  intersectionObserver = new IntersectionObserver(
    entries => {
      cb(entries);
    },
    {
      threshold: 1,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      trackVisibility: true,
      delay: 100,
    },
  );
  // start observing
  if (el) {
    intersectionObserver.observe(el);
  }
}

export function stopVisibleListen() {
  intersectionObserver?.disconnect();
}
