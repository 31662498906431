export interface RouterWatchType {
  hashChange?: boolean;
  ignoreQuery?: boolean;
  handler: (newURL: string, oldURL: string) => any;
}

// pushState 和 replaceState函数的类型声明
type HistoryStateFn = (data: any, title: string, url?: string) => void;

// 重写hitory api，在api运行时抛出指定事件
function rewriteHistoryApi(this: any, api: string) {
  const { history }: any = window;
  const rawFn = history[api];
  return function (...args: any): void {
    const rawRet = rawFn.apply(history, args);
    const evt: CustomEvent = new CustomEvent('router-change', {
      detail: { args },
    });

    window.dispatchEvent(evt);

    return rawRet;
  };
}

export class RouterWatch {
  // 是否监听hash的变化
  private hashChange: boolean = false;

  // 是否忽略query的变化
  private ignoreQuery: boolean = false;

  // URL变化的响应函数
  private handler: ((newURL: string, oldURL: string) => any) | undefined;

  // 监听的事件列表
  private listenedEvents: string[] = ['popstate', 'router-change'];

  // 当前URL
  private currentURL: string = '';

  constructor(options?: RouterWatchType) {
    if (
      !window ||
      !window.history ||
      !window.location ||
      Object.keys(window).length <= 0
    ) {
      console.error('Not in browser');
      throw new Error('Not in browser');
    }

    const { hashChange = false, ignoreQuery = true, handler } = options || {};

    if (typeof handler !== 'function') {
      console.error('Handler to report URL change must be provided');
      return;
    }
    this.hashChange = hashChange;
    this.ignoreQuery = ignoreQuery;
    this.handler = handler;
    this.currentURL = window.location.href;

    window.history.pushState = rewriteHistoryApi('pushState') as HistoryStateFn;
    window.history.replaceState = rewriteHistoryApi(
      'replaceState',
    ) as HistoryStateFn;

    // 添加监听器
    this.addListeners();
  }

  // 销毁
  public destroy(): void {
    this.removeListeners();
  }

  public getActiveURL(): string {
    return this.currentURL;
  }

  private addListeners(): void {
    const { hashChange } = this;
    if (hashChange) {
      this.listenedEvents.push('hashchange');
    }
    this.onURLChange = this.onURLChange.bind(this);
    this.listenedEvents.forEach((evtType: string) => {
      window.addEventListener(evtType, this.onURLChange, false);
    });
  }

  private onURLChange(): void {
    const { currentURL, handler } = this;
    const oldURL = currentURL;
    const newURL = window.location.href;
    this.currentURL = newURL;
    const sameURL: boolean = this.compareURL(newURL, oldURL);

    if (!sameURL) {
      handler?.(newURL, oldURL);
    }
  }

  private removeListeners(): void {
    this.listenedEvents.forEach((evtType: string) => {
      window.removeEventListener(evtType, this.onURLChange, false);
    });
  }

  // 比较url是否一样，特别关注query
  private compareURL(urlA: string, urlB: string): boolean {
    const { origin: originA, pathname: pathnameA } = new URL(urlA);
    const { origin: originB, pathname: pathnameB } = new URL(urlB);

    return this.ignoreQuery
      ? originA + pathnameA === originB + pathnameB
      : urlA === urlB;
  }
}
