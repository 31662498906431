import { Tag } from '@okee-uikit/react';
import { useMemo } from 'react';
import { useField, observer } from '@formily/react';
import { ArrayField } from '@formily/core';
import { cloneDeep } from 'lodash-es';
import { QuestionConfig } from '../../types/feelgood';
import ComponentStore from '../../store';
import { shuffle } from '../../util';
import styles from './style.module.less';

export default observer(() => {
  const field = useField<ArrayField>();
  const questionConfig: QuestionConfig = field.data;
  const { defaultText } = ComponentStore.useContext();
  const options = useMemo(() => {
    const options =
      cloneDeep(questionConfig.multiChoiceTagData?.optionList) || [];
    return !questionConfig.multiChoiceTagData?.randomConfig.enable
      ? options
      : shuffle(
          options,
          questionConfig.multiChoiceTagData?.randomConfig.fixedOptionKey,
        );
  }, [questionConfig.multiChoiceTagData]);
  field.setValidator(val => {
    if (questionConfig.multiChoiceTagData?.isRequired && val?.length === 0) {
      return defaultText?.is_require;
    }
    if (
      questionConfig.multiChoiceTagData?.maxCnt &&
      questionConfig.multiChoiceTagData?.maxCnt < val?.length
    ) {
      return defaultText.mulit_choice_limit.replace(
        /{num}/,
        questionConfig.multiChoiceTagData?.maxCnt.toString(),
      );
    }
    return true;
  });
  return options.length ? (
    <div className={styles['multi-choice-tag']}>
      {options.map(({ optionKey, text }) => {
        const checked = field.value?.includes(optionKey);
        return (
          <Tag.Checkable
            key={optionKey}
            checked={checked}
            onChange={isChecked => {
              if (isChecked) {
                // 选中，插入
                field.setValue([...new Set(field.value?.concat(optionKey))]);
              } else {
                // 没选中，剔除
                field.setValue(field.value.filter(item => item !== optionKey));
              }
              field.validate().catch((e: any) => e);
            }}
          >
            {text}
          </Tag.Checkable>
        );
      })}
    </div>
  ) : null;
});
