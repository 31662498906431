/* eslint-disable no-nested-ternary */
import { Device } from '@byted-feelgood/components';
import device from 'current-device';

export function getDevice() {
  return device.desktop()
    ? Device.PC
    : device.android()
    ? Device.Android
    : device.ios()
    ? Device.IOS
    : Device.Unknown;
}
