// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feelgood-deliverer-sdk__npsScore--bNJ4r{align-items:flex-start;border-radius:var(--fg-theme-border-radius);display:flex;flex-direction:column;width:100%}.feelgood-deliverer-sdk__npsScore--bNJ4r .feelgood-deliverer-sdk__npsScoreNum--QbZms{align-items:center;display:flex;gap:2px;justify-content:center;margin-bottom:var(--fg-theme-choice-bottom);width:100%}.feelgood-deliverer-sdk__npsScore--bNJ4r .feelgood-deliverer-sdk__npsScoreNum--QbZms .feelgood-deliverer-sdk__npsScoreItem--rt75Q{align-items:center;background:var(--fg-theme-nps-bg);border-radius:var(--fg-theme-border-radius);color:var(--fg-theme-nps-text);cursor:pointer;display:flex;flex:1 1;flex-direction:row;font-size:14px;font-weight:600;height:42px;justify-content:center}.feelgood-deliverer-sdk__npsScore--bNJ4r .feelgood-deliverer-sdk__npsScoreNum--QbZms .feelgood-deliverer-sdk__npsScoreItem--rt75Q:hover,.feelgood-deliverer-sdk__npsScore--bNJ4r .feelgood-deliverer-sdk__npsScoreNum--QbZms .feelgood-deliverer-sdk__npsScoreItemClick--kdvFW{background:var(--fg-theme-nps-bg-select);color:var(--fg-theme-nps-text-select)}.feelgood-deliverer-sdk__npsScore--bNJ4r .feelgood-deliverer-sdk__npsScoreText--HNVyl{color:var(--fg-theme-nps-tip);display:flex;font-size:14px;justify-content:space-between;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"npsScore": "feelgood-deliverer-sdk__npsScore--bNJ4r",
	"npsScoreNum": "feelgood-deliverer-sdk__npsScoreNum--QbZms",
	"npsScoreItem": "feelgood-deliverer-sdk__npsScoreItem--rt75Q",
	"npsScoreItemClick": "feelgood-deliverer-sdk__npsScoreItemClick--kdvFW",
	"npsScoreText": "feelgood-deliverer-sdk__npsScoreText--HNVyl"
};
export default ___CSS_LOADER_EXPORT___;
