import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  ConfigProvider as OkeeDesignConfigProvider,
  ErrorBoundary,
} from '@okee-uikit/react';
import {
  ShowQuestionnaireEntry,
  EDefaultTriggerKey,
  ShowType,
  ShowConfig,
  IndependentEntry,
  urlSubmitString,
} from '@byted-feelgood/components';
import { merge } from 'lodash-es';
import { getSurveyLinkID } from '@byted-feelgood/components/src/util';
import qs from 'qs';
import {
  IEmbeddedQuestionnaireOptions,
  IEmbeddedQuestionnaireRef,
} from '../../types';
import {
  debounceSendSlardar,
  ECustomEvent,
  sendSlardarEvent,
} from '@/libs/slardar';
import { getDevice } from '@/utils';

interface IEmbeddedQEntryProps {
  host: string;
  language: string;
  isRTL: boolean;
  options?: IEmbeddedQuestionnaireOptions;
  sign?: Record<string, string>;
  showQuestionnaireEntry: ShowQuestionnaireEntry;
  showType?: ShowType;
  postEvent: (
    triggerKey: EDefaultTriggerKey | string,
    param?: {
      sign?: { [key: string]: string };
      questionnaireID?: string;
    },
  ) => Promise<any>;
  unmount: () => void;
}
/**
 * 嵌入式问卷
 */
const EmbeddedEntry = forwardRef<
  IEmbeddedQuestionnaireRef,
  IEmbeddedQEntryProps
>((props, ref) => {
  const {
    isRTL = false,
    sign,
    host,
    language,
    options,
    showQuestionnaireEntry,
    postEvent,
    unmount,
  } = props;
  const { questionnaireID, surveyID, surveyVersion, submitID } =
    showQuestionnaireEntry;
  /**
   * 是否 问卷组件挂了
   */
  const isQuestionnaireErrorRef = useRef(false);
  /**
   * 问卷组件挂载的时间
   */
  const questionnaireMountedTimestampRef = useRef(0);

  const sendEvent = useCallback(
    (
      event: string,
      data?: Parameters<typeof sendSlardarEvent>[1],
      debounce?: boolean,
    ) => {
      const sendEventFunc = debounce ? debounceSendSlardar : sendSlardarEvent;
      sendEventFunc(event, {
        metrics: data?.metrics,
        categories: {
          questionnaireID,
          surveyID,
          surveyVersion,
          ...data?.categories,
        },
      });
    },
    [questionnaireID, surveyID, surveyVersion],
  );
  const reportEvent = useCallback<typeof postEvent>(
    (event, param) => {
      return postEvent(event, {
        questionnaireID,
        sign,
        ...param,
      });
    },
    [questionnaireID, sign],
  );
  useEffect(() => {
    if (!isQuestionnaireErrorRef.current) {
      sendEvent(ECustomEvent.QuestionnaireMounted);
      (async () => {
        options?.onMounted &&
          (await options?.onMounted?.(showQuestionnaireEntry));
      })();
    }

    return () => {
      if (!isQuestionnaireErrorRef.current) {
        sendEvent(ECustomEvent.QuestionnaireUnmounted);
        options?.onUnmounted?.();
      }
    };
  }, [options, reportEvent, questionnaireID, sendEvent]);
  /**
   * 暴露到外部的接口
   */
  useImperativeHandle(
    ref,
    () => ({
      setQuestionnaireStatusDefault: () => {
        questionnaireMountedTimestampRef.current = 0;
      },
    }),
    [reportEvent, questionnaireID, sendEvent],
  );

  const theme = useMemo(() => {
    let finTheme = {};
    try {
      finTheme = merge(
        {},
        JSON.parse(showQuestionnaireEntry.theme || '{}'),
        options?.theme,
      );
    } catch (e) {
      console.error('getThemeFail');
    }
    return finTheme;
  }, [options?.theme, showQuestionnaireEntry.theme]);

  return (
    <OkeeDesignConfigProvider prefixCls={`feelgood-deliverer-okee`}>
      <ErrorBoundary
        onError={(error, stack) => {
          isQuestionnaireErrorRef.current = true;
          sendSlardarEvent(ECustomEvent.QuestionnaireError, {
            categories: {
              name: error?.name,
              cause: error?.cause,
              message: error?.message,
              stack,
            },
          });
          throw error;
        }}
      >
        <IndependentEntry
          isRTL={isRTL}
          questionnaire={{} as ShowConfig}
          entranceConfig={showQuestionnaireEntry.entranceConfig}
          isAutoPopup={showQuestionnaireEntry.isAutoPopup}
          defaultTextMap={showQuestionnaireEntry.defaultTranslation}
          onEntryShow={() => {
            reportEvent(EDefaultTriggerKey.EntryShow, {
              questionnaireID,
            });
            sendSlardarEvent(ECustomEvent.QuestionnaireEntryShow);
          }}
          onEntryClick={() => {
            reportEvent(EDefaultTriggerKey.EntryClick, {
              questionnaireID,
            });
            sendSlardarEvent(ECustomEvent.QuestionnaireEntryClick);
            const id = getSurveyLinkID(surveyID || '');
            window.open(
              `https://${host}/survey/${id}/${
                language || 'en'
              }?${urlSubmitString}=${submitID}&${qs.stringify(sign)}`,
            );
          }}
          onEnd={() => {
            unmount();
          }}
          {...{
            ...options,
            theme,
            device: options?.device || getDevice(),
          }}
        />
      </ErrorBoundary>
    </OkeeDesignConfigProvider>
  );
});
export default EmbeddedEntry;
