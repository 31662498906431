// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feelgood-deliverer-sdk__multi-choice-tag--ayFsI{display:flex;flex-wrap:wrap;justify-content:flex-start}.feelgood-deliverer-sdk__multi-choice-tag--ayFsI .feelgood-deliverer-okee-tag{border-radius:var(--fg-theme-border-radius);font-size:14px;font-style:normal;font-weight:400;margin:var(--fg-direction-tag-margin)}.feelgood-deliverer-sdk__multi-choice-tag--ayFsI .feelgood-deliverer-okee-tag-checkable.feelgood-deliverer-okee-tag-fill{background:var(--fg-theme-multi-choice-tag-bg);border:1px solid transparent;border-radius:var(--fg-theme-border-radius);color:var(--fg-theme-multi-choice-tag-text)}.feelgood-deliverer-sdk__multi-choice-tag--ayFsI .feelgood-deliverer-okee-tag-size-md:not(.feelgood-deliverer-okee-tag-text){line-height:18px;padding:8px 16px}.feelgood-deliverer-sdk__multi-choice-tag--ayFsI .feelgood-deliverer-okee-tag-checkable.feelgood-deliverer-okee-tag-fill:hover{background:var(--fg-theme-multi-choice-tag-bg-select);border:1px solid transparent;color:var(--fg-theme-multi-choice-tag-text-select)}.feelgood-deliverer-sdk__multi-choice-tag--ayFsI .feelgood-deliverer-okee-tag-checkable.feelgood-deliverer-okee-tag-fill.feelgood-deliverer-okee-tag-checked,.feelgood-deliverer-sdk__multi-choice-tag--ayFsI .feelgood-deliverer-okee-tag-checkable.feelgood-deliverer-okee-tag-fill.feelgood-deliverer-okee-tag-checked:hover{background:var(--fg-theme-multi-choice-tag-bg-select);border:1px solid var(--fg-theme-multi-choice-tag-border-select);color:var(--fg-theme-multi-choice-tag-text-select)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multi-choice-tag": "feelgood-deliverer-sdk__multi-choice-tag--ayFsI",
	"multiChoiceTag": "feelgood-deliverer-sdk__multi-choice-tag--ayFsI"
};
export default ___CSS_LOADER_EXPORT___;
