import { useMemo } from 'react';
import { useField, observer } from '@formily/react';
import { Field } from '@formily/core';
import classNames from 'classnames';
import { QuestionConfig } from '../../types/feelgood';
import ComponentStore from '../../store';
import styles from './style.module.less';

/**
 * nps
 */
export default observer(() => {
  const { defaultText, defaultTextSign } = ComponentStore.useContext();
  const field = useField<Field<any, any, any, string>>();
  const questionConfig: QuestionConfig = field.data;
  const scoreText = useMemo(() => {
    return {
      s0text:
        questionConfig.npsChoiceData?.s0Text === defaultTextSign
          ? defaultText?.nps_0
          : questionConfig.npsChoiceData?.s0Text,
      s10text:
        questionConfig.npsChoiceData?.s10Text === defaultTextSign
          ? defaultText?.nps_10
          : questionConfig.npsChoiceData?.s10Text,
    };
  }, [questionConfig.npsChoiceData, defaultText, defaultTextSign]);
  const options = useMemo(() => {
    return questionConfig.npsChoiceData?.optionList || [];
  }, [questionConfig.npsChoiceData?.optionList]);
  field.setValidator(val => {
    if (questionConfig.npsChoiceData?.isRequired && !val) {
      return defaultText?.is_require;
    }
    return true;
  });
  return (
    <div className={styles.npsScore}>
      <div className={styles.npsScoreNum}>
        {options.map(i => {
          return (
            <div
              className={classNames(
                styles.npsScoreItem,
                field.value === i.optionKey ? styles.npsScoreItemClick : '',
              )}
              key={i.optionKey}
              onClick={() => {
                field.setValue(i.optionKey);
                field.validate();
              }}
            >
              {i.score}
            </div>
          );
        })}
      </div>
      <div className={styles.npsScoreText}>
        <div>{scoreText.s0text}</div>
        <div>{scoreText.s10text}</div>
      </div>
    </div>
  );
});
