// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  createForm,
  Field as TypeField,
  onFieldValueChange,
  onFormSubmitStart,
  onFormSubmitFailed,
  onFormSubmitSuccess,
  onFormSubmitEnd,
  DataField,
  Form,
} from '@formily/core';
import { FormProvider, FormConsumer } from '@formily/react';
import { Button } from '@okee-uikit/react';
import { debounce, isEmpty } from 'lodash-es';
import { CheckOne, CloseSmall } from '@icon-park/react';
import classNames from 'classnames';
import '../../feelgood.less';
import {
  Device,
  QuestionAnswer,
  QuestionType,
  ShowConfig,
  ShowType,
  SurveyEntranceConfig,
} from '../../types/feelgood';
import { LoadingIcon } from '../../components';
import ComponentStore from '../../store';
import styles from './style.module.less';
import Question, {
  getQuestionAnswerData,
  questionTypeToFieldComponentMap,
} from './question';
import { questionDisplayCondition } from './question-display-condition';

export interface IStyleType {
  /**
   * 位置和层级
   *  right 默认32px
   *  bottom 默认40px
   *  z-index 默认body fixed 10000
   */
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  zIndex?: number;
  zIndexMask?: number;
  MaskColor?: string;
  /**
   * 窗口宽度，默认pc：400px，mobile：100%
   */
  width?: string;
  /**
   * 可滚动区域高度，350px
   */
  maxHeight?: string;
  /**
   * 问卷弹出方向
   */
  popDirection?: 'left' | 'right';
}

export interface ThemeType {
  themeId?: string;
  global?: {
    light: Record<string, string>;
    dark: Record<string, string>;
    desktop: Record<string, string>;
    mobile: Record<string, string>;
    [key: string]: Record<string, string>;
  };
  question?: {
    singleChoice?: Record<string, string>;
    multiChoice?: Record<string, string>;
    multiChoiceTag?: Record<string, string>;
    npsChoice?: Record<string, string>;
    rating?: Record<string, string>;
    shortAnswer?: Record<string, string>;
    textarea?: Record<string, string>;
  };
}

export enum ClosePosEnum {
  Default = 1,
  Entry = 2,
  Mask = 3,
}

export interface IQuestionnaireOptions {
  /**
   * 白天/黑夜模式
   * @defaults light
   */
  mode?: 'light' | 'dark';
  /**
   * 主题json
   * 无黑夜模式的主题，兜底白天主题
   */
  theme?: ThemeType;
  /**
   * 问卷位置、层级、宽高的样式，
   */
  style?: IStyleType;
  /**
   * 设备
   */
  device?: Device;
  /**
   * 入口图标
   */
  entryUrl?: string;
  /**
   * 是否沉默执行，即提交接口报错也在用户侧界面显示成功
   * @defaults true
   */
  silence?: boolean;
  /**
   * 是否显示，提交成功后的反馈页面
   * @defaults true
   */
  feedback?: boolean;
  /**
   * 是否显示关闭按钮
   * @defaults true
   */
  showCloseButton?: boolean;
  /**
   * 是否自动滚动问卷
   * @defaults false
   */
  autoScroll?: boolean;
  /**
   * 移动端是否有蒙层，默认false
   */
  mobileHasMask?: boolean;
  /**
   * 问卷预填数据，使用方式查看文档
   * https://bytedance.feishu.cn/docx/PfmVdTMBvofzwWxz1m3czxifnsp
   */
  prefillData?: Record<string, any>;
  /**
   * 当输入框获取焦点时，包括 input \ textarea
   */
  onInputFocus?: (event: FocusEvent) => any;
  /**
   * 当输入框失去焦点时，包括 input \ textarea
   */
  onInputBlur?: (event: FocusEvent) => any;
  /**
   * 点击了右上角,入口，蒙层时，触发问卷关闭回调
   * closePos触发位置 1：右上角X，2：入口，3：蒙层
   * trueClose问卷是否真正卸载。存在入口时关闭问卷只会最小化，该参数返回false
   */
  onClose?: (closePos: ClosePosEnum, trueClose?: boolean) => any;
  /**
   * 问卷提交时
   */
  onSubmitStart?: (form?: Form<any>) => any;
  /**
   * 问卷提交失败时
   */
  onSubmitFailed?: () => any;
  /**
   * 问卷提交成功时
   */
  onSubmitSuccess?: () => any;
  /**
   * 问卷提交过程结束，不论成功或失败都会触发
   */
  onSubmitEnd?: () => any;
  /**
   * 当问卷展示时
   */
  onSurveyShow?: () => any;
}

export interface IQuestionnaireProps extends IQuestionnaireOptions {
  /**
   * 问卷内容主体
   */
  questionnaire: ShowConfig;
  /**
   * 问卷类型，问卷和入口
   */
  showType?: ShowType;
  /**
   * 问卷自动展开
   */
  isAutoPopup?: boolean;
  /**
   * 问卷入口
   */
  entranceConfig?: SurveyEntranceConfig;
  /**
   * 问卷默认文案
   */
  defaultTextMap?: Record<string, string>;
  /**
   * 是否预览模式
   * @default false
   */
  preview?: boolean;
  /**
   * 直接引用问卷本体
   * 用于class设置
   * @default false
   */
  directed?: boolean;
  /**
   * 是否显示关闭按钮
   * @default false
   */
  closeButton?: boolean;
  /**
   * 问卷语言方向
   * @default false
   */
  isRTL?: boolean;
  /**
   * 入口点击
   */
  onEntryClick?: (state: boolean) => any;
  /**
   * 当入口展示时
   */
  onEntryShow?: () => any;
  /**
   * 表单填写
   */
  onQuestionAnswerChange?: (field: DataField, form: Form) => any;
  /**
   * 表单提交
   */
  onSubmit?: (answerData: QuestionAnswer[]) => Promise<{ submitID?: string }>;
  /**
   * 问卷全部流程结束
   */
  onEnd?: () => any;
}

enum ESystemFieldKey {
  IsSubmitButtonHidden = 'IsSubmitButtonHidden',
}

export const QuestionnaireMain: React.FC<IQuestionnaireProps> = props => {
  const {
    onClose,
    onQuestionAnswerChange,
    onSubmit,
    onSubmitStart,
    onSubmitSuccess,
    onSubmitFailed,
    onSubmitEnd,
    onInputFocus,
    onInputBlur,
    onSurveyShow,
    onEnd,
    silence = true,
    questionnaire,
    preview,
    feedback = true,
    autoScroll = false,
    mode = 'light',
    theme,
    style = {},
    device,
    defaultTextMap,
    directed = true,
    isRTL = false,
    closeButton = false,
    showCloseButton = true,
    prefillData = {},
  } = props;
  const feelgoodRef = useRef<HTMLDivElement>(null);
  const questionnaireRef = useRef<HTMLDivElement>(null);
  const questionListRef = useRef<HTMLDivElement>(null);
  const {
    defaultText,
    setDefaultText,
    defaultTextSign,
    getCurrentDevice,
    setThemeData,
  } = ComponentStore.useContext();
  /**
   * 设置主题和默认文案
   */
  useEffect(() => {
    if (theme && mode && directed && feelgoodRef.current) {
      setThemeData(theme, mode, getCurrentDevice(device), feelgoodRef.current);
    }
  }, [theme, mode, device, directed, feelgoodRef]);
  useEffect(() => {
    setDefaultText(defaultTextMap);
  }, [defaultTextMap]);
  /**
   * 问卷标题
   */
  const headerConfig = useMemo(() => {
    return questionnaire?.pageConfig?.[0]?.headerConfig;
  }, [questionnaire]);
  /**
   * 问卷问题列表
   */
  const questionList = useMemo(() => {
    return (
      questionnaire?.pageConfig?.[0]?.questionList?.filter(({ questionType }) =>
        // 暂不支持没实现组件的题型
        Boolean(questionTypeToFieldComponentMap[questionType]),
      ) || []
    );
  }, [questionnaire]);
  /**
   * 提交成功后的ID
   */
  const [submitID, setSubmitID] = useState('');
  /**
   * 处理提交
   */
  const handleSubmit = useCallback(
    async (values: Record<string, string | string[]>): Promise<any> => {
      const answerData = questionList
        .filter(({ questionKey }) => {
          return (
            Boolean(values?.[questionKey]) && !isEmpty(values?.[questionKey])
          );
        })
        .map(({ questionKey, questionType }, index) => {
          const answerData = getQuestionAnswerData(
            questionType,
            values?.[questionKey],
          );
          return {
            questionKey,
            questionType,
            answerData,
            showIndex: index,
          };
        });
      if (preview) {
        // 预览模式
        return new Promise(resolve => {
          setTimeout(() => {
            afterSubmit();
            resolve(true);
          }, 1000);
        });
      } else {
        return onSubmit?.(answerData).then(({ submitID }) => {
          if (submitID) {
            afterSubmit(submitID);
          }
          return {
            submitID,
          };
        });
      }
    },
    [onSubmit, preview, questionList],
  );
  /**
   * 第一题评分题直接提交
   * @param firstQuestionField
   * @param form
   */
  const isFirstRatingQuestionSubmit = (
    firstQuestionField: TypeField,
    form: Form,
  ) => {
    const [firstQuestion, ...restQuestionList] = questionList;
    const firstQuestionFieldValue = firstQuestionField.value;
    /**
     * 是否 当前第一题的值没有被其他题目关联
     */
    const isFirstQuestionValueNotACondition = !questionList.some(
      ({ displayCondition }) =>
        displayCondition.eachConfig.some(
          ({ questionKey, optionKeyList }) =>
            questionKey === firstQuestion.questionKey &&
            optionKeyList.some(key => key === firstQuestionFieldValue),
        ),
    );
    /**
     * 是否 除第一题外，其他题目一开始都是不可见的
     */
    const isRestQuestionListNotVisiable =
      !restQuestionList?.length ||
      restQuestionList.every(({ displayCondition }) => displayCondition.enable);
    /**
     * 第一题是评分题 && 该评分题目前被选中的值 没有被其他题关联，隐藏提交按钮
     */
    form.setValuesIn(
      ESystemFieldKey.IsSubmitButtonHidden,
      isFirstQuestionValueNotACondition && isRestQuestionListNotVisiable,
    );
    if (
      firstQuestionFieldValue &&
      isFirstQuestionValueNotACondition &&
      isRestQuestionListNotVisiable
    ) {
      /**
       * 第一题是评分题 &&
       * 该第一评分题目前被选中的值，没有被其他题关联 &&
       * 其他题目一开始都是不可见的
       * 则直接提交
       */
      form.submit(handleSubmit);
    }
  };
  /**
   * 核心表单
   */
  const form = useMemo(() => {
    /**
     * 第一题的配置
     */
    const [firstQuestion, ...restQuestionList] = questionList;
    /**
     * 是否第一条是评分题
     */
    const isFirstRatingQuestion =
      firstQuestion?.questionType === QuestionType.Rating ||
      firstQuestion?.questionType === QuestionType.NPS;
    /**
     * 是否 一开始就隐藏提交按钮
     * = 第一题是评分题 且 （没有其他题目了 或 其他题目都是有逻辑关联的）
     */
    const isSubmitButtonHidden =
      isFirstRatingQuestion &&
      (!restQuestionList?.length ||
        restQuestionList?.every(
          ({ displayCondition }) => displayCondition.enable,
        ));
    return createForm({
      initialValues: {
        [ESystemFieldKey.IsSubmitButtonHidden]: isSubmitButtonHidden,
      },
      effects() {
        onSubmitStart && onFormSubmitStart(onSubmitStart);

        onSubmitSuccess && onFormSubmitSuccess(onSubmitSuccess);
        onFormSubmitFailed(() => {
          silence && afterSubmit();
          onSubmitFailed?.();
        });
        onSubmitEnd && onFormSubmitEnd(onSubmitEnd);
        /**
         * 通配符，监听所有 field 变化，并上报
         * 相比 onFormValuesChange ，这里能知道哪个 field 发生了变化
         */
        onFieldValueChange('*', (valueChangeField, form) => {
          questionDisplayCondition(
            questionList,
            valueChangeField,
            form,
            autoScroll,
          );
          onQuestionAnswerChange?.(valueChangeField, form);
        });

        if (isFirstRatingQuestion) {
          // 如果第一题是评分题，监听值变化，可能直接提交
          onFieldValueChange(
            firstQuestion.questionKey,
            isFirstRatingQuestionSubmit,
          );
        }
      },
    });
  }, [questionList]);

  const afterSubmit = (submitId?: string) => {
    if (feedback) {
      setSubmitID(submitId || Math.random().toString());
      form.setValuesIn(ESystemFieldKey.IsSubmitButtonHidden, true);
      setTimeout(() => {
        onEnd?.();
      }, 2000);
    } else {
      setSubmitID(submitId || Math.random().toString());
      onEnd?.();
    }
  };
  /**
   * 事件监听
   */
  useEffect(() => {
    if (feelgoodRef.current) {
      const inputTags = ['input', 'textarea'];
      feelgoodRef.current.addEventListener(
        'focus',
        (event: any) => {
          if (
            inputTags.includes(
              (event.target?.tagName as string)?.toLocaleLowerCase(),
            )
          ) {
            onInputFocus?.(event.target);
          }
        },
        true,
      );
      feelgoodRef.current.addEventListener(
        'blur',
        (event: any) => {
          if (
            inputTags.includes(
              (event.target?.tagName as string)?.toLocaleLowerCase(),
            )
          ) {
            onInputBlur?.(event.target);
          }
        },
        true,
      );
    }
  }, [onInputBlur, onInputFocus]);

  useEffect(() => {
    if (questionnaireRef.current) {
      onSurveyShow?.();
      questionnaireRef.current.addEventListener(
        'scroll',
        debounce(e => {
          e.target.focus?.();
        }, 250),
      );
    }
    if (form && prefillData) {
      form?.setValues({
        ...form.values,
        ...prefillData,
      });
    }
  }, [form, prefillData]);
  /**
   * 预览、静默特殊逻辑
   */
  useEffect(() => {
    if (submitID && preview) {
      setTimeout(() => {
        setSubmitID('');
        form.setValuesIn(ESystemFieldKey.IsSubmitButtonHidden, false);
        form.reset();
      }, 3000);
    }
  }, [form, preview, submitID]);
  return (
    <FormProvider form={form}>
      <div
        className={classNames(
          {
            feelgood: directed,
            [`feelgood__theme--${mode}`]: directed,
            [`feelgood__device--${getCurrentDevice(device)}`]: directed,
          },
          `feelgood__direction--${isRTL ? 'rtl' : 'ltr'}`,
          styles.questionnaire_main,
        )}
        ref={feelgoodRef}
      >
        {submitID && feedback ? (
          <div className={classNames(styles.feedback, 'feelgood-feedback')}>
            <CheckOne theme="filled" size="52" strokeWidth={3} />
            <div
              className={classNames(
                styles.feedback__content,
                'feelgood-feedback_content',
              )}
            >
              {(questionnaire?.feedbackText === defaultTextSign
                ? defaultText?.default_feedback
                : questionnaire?.feedbackText) || 'Thanks for your feedback'}
            </div>
          </div>
        ) : (
          <>
            {!closeButton && showCloseButton && (
              <div className={classNames(styles.header, 'feelgood-header')}>
                <CloseSmall
                  theme="filled"
                  size="24"
                  onClick={() => {
                    onClose?.(ClosePosEnum.Default, true);
                  }}
                />
              </div>
            )}
            <div
              className={classNames(
                styles.questionnaire,
                'feelgood-questionnaire',
              )}
              id="feelgood-questionnaire-content"
              ref={questionnaireRef}
              style={{
                maxHeight: style?.maxHeight,
              }}
            >
              {headerConfig ? (
                <div
                  className={classNames(
                    styles.questionnaire__header,
                    'feelgood-questionnaire_header',
                  )}
                >
                  {headerConfig?.title && (
                    <div
                      className={classNames(
                        styles.questionnaire__header__title,
                        'feelgood-questionnaire_header-title',
                      )}
                    >
                      {headerConfig?.title}
                    </div>
                  )}
                  {headerConfig?.instructions && (
                    <div
                      className={classNames(
                        styles.questionnaire__header__instructions,
                        'feelgood-questionnaire_header-instructions',
                      )}
                    >
                      {headerConfig?.instructions}
                    </div>
                  )}
                </div>
              ) : null}

              <div
                className={classNames(
                  styles['questionnaire__question-list'],
                  'feelgood-questionnaire_question-list',
                )}
                ref={questionListRef}
              >
                {questionList.map((question, index) => {
                  return (
                    <Question
                      key={question.questionKey}
                      question={question}
                      index={index}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
        <FormConsumer>
          {form => (
            <>
              {form.values[ESystemFieldKey.IsSubmitButtonHidden] ? null : (
                <div
                  className={classNames(
                    styles.footer,
                    'feelgood-questionnaire_footer',
                  )}
                >
                  <Button
                    className="feelgood-questionnaire_footer-submit"
                    type="primary"
                    size="sm"
                    onClick={async () => {
                      try {
                        await form.validate();
                        form.submit(handleSubmit);
                      } catch (e: any) {
                        // eslint-disable-next-line no-console
                        console.log('validate fail', e);
                      }
                    }}
                    disabled={form.submitting}
                  >
                    {form.submitting ? (
                      <LoadingIcon />
                    ) : (
                      (questionnaire?.submitText === defaultTextSign
                        ? defaultText?.default_submit
                        : questionnaire?.submitText) || 'Submit'
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
        </FormConsumer>
      </div>
    </FormProvider>
  );
};

export default function QuestionnaireComponent(prop: IQuestionnaireProps) {
  return (
    <ComponentStore.Provider>
      <QuestionnaireMain {...prop} />
    </ComponentStore.Provider>
  );
}
