import classNames from 'classnames';
import {
  QuestionnaireMain,
  IQuestionnaireProps,
} from '../questionnaire/questionnaire';
import styles from './style.module.less';
import '../../feelgood.less';

const Container: React.FC<IQuestionnaireProps & { show: boolean }> = props => {
  const { show, style = {} } = props;
  return (
    <div
      className={classNames(
        styles.questionnaire__container,
        styles.container,
        show ? styles.container_enter : '',
        'feelgood-widgets_container',
      )}
      style={{
        width: style?.width,
      }}
    >
      <QuestionnaireMain {...props} directed={false} />
    </div>
  );
};

export default Container;
