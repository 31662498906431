/**
 * 系统默认触发事件事件
 */
export enum EDefaultTriggerKey {
  /**
   * 进入页面 @view
   */
  View = '@view',
  /**
   * 下发问卷 @send
   */
  Send = '@send',
  /**
   * 入口曝光 @entry_show
   */
  EntryShow = '@entry_show',
  /**
   * 入口点击 @entry_click
   */
  EntryClick = '@entry_click',
  /**
   * 关闭按钮点击(非拒答) @close_click
   */
  CloseClick = '@close_click',
  /**
   * 点击拒绝 @reject
   */
  Reject = '@reject',
  /**
   * 问卷内容曝光 @show
   */
  Show = '@show',
  /**
   * 填写问卷 @answer
   */
  Answer = '@answer',
  /**
   * 提交问卷 @submit
   */
  Submit = '@submit',
}
