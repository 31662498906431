import { Checkbox } from '@okee-uikit/react';
import { useEffect, useMemo } from 'react';
import { useField, observer, Field, ObjectField } from '@formily/react';
import { ArrayField, Field as FieldType } from '@formily/core';
import { cloneDeep } from 'lodash-es';
import {
  MultichoiceAnswerData,
  MultiChoiceOptionConfigData,
  QuestionConfig,
} from '../../types/feelgood';
import ComponentStore from '../../store';
import CommonText from '../common-text/common-text';
import { shuffle } from '../../util';
import styles from './style.module.less';

const CheckItem = ({
  option,
  index,
  field,
}: {
  option: MultiChoiceOptionConfigData;
  index: number;
  field: ArrayField;
}) => {
  useEffect(() => {
    if (!field.value) {
      field.setValue([]);
    }
  }, [field]);
  const { optionKey, text, relationInput, conflictConfig } = option;
  return (
    <div className={styles['multi-choice-item']}>
      <ObjectField name={index} initialValue={{ optionKey: '' }}>
        <div className={styles['multi-choice-check']}>
          <Field name={`optionKey`} data={field?.data}>
            {CheckField => {
              const valOptionList = field.value
                ?.filter(item => item.optionKey)
                ?.map(item => item.optionKey);
              let disabled = false;
              // 判断选项冲突，如果已选择的含有任一冲突项，置灰
              const conflictDisabled = Boolean(
                conflictConfig?.enable &&
                  valOptionList.some(val =>
                    conflictConfig?.conflictOptionKey?.includes(val),
                  ),
              );
              // 判断最大可选，超过置灰其他
              const { maxCnt } = field.data.multiChoiceData || 0;
              const maxDisabled =
                maxCnt > 0 &&
                valOptionList.length >= maxCnt &&
                !valOptionList.includes(optionKey);
              disabled = conflictDisabled || maxDisabled;
              return (
                <Checkbox
                  disabled={disabled}
                  checked={Boolean(CheckField.value)}
                  onValueChange={isChecked => {
                    if (isChecked) {
                      // 选中，插入
                      CheckField.setValue(optionKey);
                    } else {
                      // 没选中，剔除
                      CheckField.setValue('');
                    }
                    const inputField = CheckField.query(
                      '.relationInput',
                    ).take() as FieldType;
                    inputField.setDisplay(
                      isChecked && relationInput.enable ? 'visible' : 'none',
                    );
                    inputField.setValue('');
                    field.validate().catch((e: any) => e);
                  }}
                >
                  {text}
                </Checkbox>
              );
            }}
          </Field>
        </div>
        <div className={styles['multi-choice-input']}>
          <Field
            name={`relationInput`}
            required={relationInput.isMust}
            visible={false}
          >
            {inputField => {
              const isCheck = inputField
                .query('.optionKey')
                .take() as FieldType;
              inputField.setDisplay(
                isCheck?.value && relationInput.enable ? 'visible' : 'none',
              );
              return (
                <CommonText
                  customClassName={styles['multi-choice-text']}
                  value={inputField.value}
                  onChange={e => {
                    inputField.setValue(e.target.value);
                    inputField.validate().catch((e: any) => e);
                  }}
                  invalid={inputField.validateStatus === 'error'}
                />
              );
            }}
          </Field>
        </div>
      </ObjectField>
    </div>
  );
};

export default observer(() => {
  const field = useField<ArrayField>();
  const questionConfig: QuestionConfig = field.data;
  const { defaultText } = ComponentStore.useContext();
  const options = useMemo(() => {
    const options = cloneDeep(questionConfig.multiChoiceData?.optionList) || [];
    return !questionConfig.multiChoiceData?.randomConfig.enable
      ? options
      : shuffle(
          options,
          questionConfig.multiChoiceData?.randomConfig.fixedOptionKey,
        );
  }, [questionConfig.multiChoiceData]);
  field.setValidator((val: MultichoiceAnswerData[]) => {
    if (
      questionConfig.multiChoiceData?.isRequired &&
      !val?.some(i => i.optionKey)
    ) {
      return defaultText?.is_require;
    }
    return true;
  });
  return options.length ? (
    <div className={styles['multi-choice']}>
      {options.map((option, index) => {
        return (
          <CheckItem
            key={option.optionKey}
            option={option}
            index={index}
            field={field}
          />
        );
      })}
    </div>
  ) : null;
});
