// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@modern-js+webpack@1.21.6_typescript@4.7.4/node_modules/@modern-js/webpack/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feelgood-deliverer-sdk__loading--Cp449{-webkit-animation:feelgood-deliverer-sdk__around--EuvHs 1s linear infinite;animation:feelgood-deliverer-sdk__around--EuvHs 1s linear infinite}@-webkit-keyframes feelgood-deliverer-sdk__around--EuvHs{0{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes feelgood-deliverer-sdk__around--EuvHs{0{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "feelgood-deliverer-sdk__loading--Cp449",
	"around": "feelgood-deliverer-sdk__around--EuvHs"
};
export default ___CSS_LOADER_EXPORT___;
