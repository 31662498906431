// sg
import browserClient, {
  BrowserInitConfig,
  BrowserUserConfig,
} from '@slardar/web/sg';
import { AxiosError, AxiosResponse } from 'axios';
import { debounce, isPlainObject } from 'lodash-es';
import { IFeelgoodResponse } from '@/services/deliverer';

export enum ECustomEvent {
  /**
   * Initialization
   */
  Initialized = 'initialized',
  InitializedFailed = 'initialized_failed',
  InstanceDestory = 'instence_destory',

  Ajax = 'ajax',

  StartRouterListener = 'start_router_listener',
  StopRouterListener = 'stop_router_listener',
  RouterChange = 'router_change',

  // 问卷窗口可见性事件 （IntersectionObserver api）
  SurveyVisibleChange = 'survey_visible_change',
  // dom可见行事件
  DomVisibleChange = 'dom_visible_change',

  /**
   * 获取 access_token
   */
  AuthGetAuthToken = 'auth_get_auth_token',
  AuthGetAuthTokenSuccess = 'auth_get_auth_token_success',
  AuthGetAuthTokenFailed = 'auth_get_auth_token_failed',
  /**
   * 事件上报
   */
  EventReport = 'event_report',
  /**
   * 事件触发
   */
  TriggerEvent = 'trigger_event',
  TriggerEventFailed = 'trigger_event_failed',
  TriggerEventDryrun = 'trigger_event_dryrun',
  TriggerEventRenderQuestionnaire = 'trigger_event_render_questionnaire',
  // 之前问卷存在，这次触发被丢弃
  TriggerEventAbandonWithExisted = 'trigger_event_abandon_existed',
  // 传入数据直接渲染
  TriggerEventDirect = 'trigger_event_direct',
  TriggerEventDirectFailed = 'trigger_event_direct_failed',
  /**
   * 问卷
   */
  QuestionnaireMounted = 'questionnaire_mounted',
  QuestionnaireMountedCustom = 'questionnaire_mounted_custom',
  QuestionnaireUnmount = 'questionnaire_unmount',
  QuestionnaireUnmounted = 'questionnaire_unmounted',
  QuestionnaireError = 'questionnaire_error',
  QuestionnaireClose = 'questionnaire_close',
  QuestionnaireSubmit = 'questionnaire_submit',
  QuestionnaireSubmitSuccess = 'questionnaire_submit_success',
  QuestionnaireSubmitFailed = 'questionnaire_submit_failed',
  QuestionnaireQuestionAnswerChange = 'questionnaire_question_answer_change',
  QuestionnaireEntryShow = 'questionnaire_entry_show',
  QuestionnaireEntryClick = 'questionnaire_entry_click',
  QuestionnaireSurveyShow = 'questionnaire_survey_show',
  QuestionnaireSurveyHidden = 'questionnaire_survey_hidden',
}
/**
 * 初始化 slardar
 * @param config
 */
export function initSlardar(config: BrowserInitConfig) {
  browserClient('init', {
    ...config,
    env: IS_DEV ? 'dev' : config?.env || 'production',
  });
  browserClient('start');
  return browserClient('config');
}
/**
 * 获取 slardar 配置
 * @param config
 */
export function getSlardarConfig() {
  return browserClient('config');
}
/**
 * 设置 slardar 配置，一般只更新 user id
 * @param config
 */
export function setSlardarConfig(config: Partial<BrowserUserConfig>) {
  return browserClient('config', config);
}
/**
 * 设置 slardar context，每一次上报都会带上的
 * @param context
 */
export function setSlardarContext(context: Record<string, any>) {
  browserClient('context.merge', context);
}
export const debounceSendSlardar = debounce(sendSlardarEvent, 250);
/**
 * 自定义事件
 * @param name
 * @param metrics 数值指标
 * @param categories 字符串
 */
export function sendSlardarEvent(
  name: string,
  data?: {
    metrics?: {
      [key: string]: number;
    };
    categories?: {
      [key: string]: any;
    };
  },
) {
  if (getSlardarConfig()?.env !== 'production') {
    console.log(
      `feelgood deliverer event: %c${name} ${
        name === 'ajax'
          ? `%c${data?.categories?.pathname || data?.categories?.url || ''}`
          : ''
      }`,
      'color:red',
      name === 'ajax' ? 'color:blue' : '',
      data,
    );
  }
  const metrics: { [key: string]: number } = {};
  const categories: { [key: string]: any } = {
    timestamp: new Date().toISOString(),
  };

  if (data && isPlainObject(data)) {
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'number') {
        metrics[key] = value;
      } else {
        categories[key] = value;
      }
    }
  }
  browserClient('sendEvent', {
    name,
    ...data,
  });
}
type TLevel = 'debug' | 'info' | 'warn' | 'error';
/**
 * 自定义日志
 * @param name
 * @param metrics
 * @param categories
 */
export function sendSlardarLog(
  level: TLevel,
  content: string,
  extra?: { [key: string]: string | number },
) {
  browserClient('sendLog', {
    level,
    content,
    extra,
  });
}
interface IAxiosTimeout {
  message: string;
  name: string;
  stack: string;
  config: {
    url: string;
    method: string;
    params: any;
    data: string;
    headers: {
      Accept: string;
      'Content-Type': string;
      'X-csrftoken': string;
    };
    baseURL: string;
    transformRequest: any[];
    transformResponse: any[];
    timeout: number;
    xsrfCookieName: string;
    xsrfHeaderName: string;
    maxContentLength: number;
  };
  code: string;
}

const getAxiosInfo = (
  res:
    | AxiosResponse<IFeelgoodResponse>
    | AxiosError<IFeelgoodResponse>
    | IAxiosTimeout,
) => {
  const { config } = res;
  const axiosResponse = res as AxiosResponse<IFeelgoodResponse>;
  const axiosError = res as AxiosError<IFeelgoodResponse>;
  const axiosTimeout = res as IAxiosTimeout;
  const url = axiosResponse?.request?.responseURL || axiosTimeout?.config?.url;
  const status = String(
    axiosError.response?.status || axiosResponse.status || axiosTimeout.code,
  );
  const headers =
    axiosError.response?.headers ||
    axiosResponse.headers ||
    axiosTimeout.config?.headers;
  const param =
    typeof config.data === 'string'
      ? config.data
      : JSON.stringify(config.data || config.params);
  const code =
    axiosTimeout.code ||
    axiosTimeout.name ||
    String(
      axiosError.isAxiosError
        ? axiosError.response?.data?.code
        : axiosResponse.data?.code,
    );
  const data = axiosError.isAxiosError
    ? axiosError.response?.data?.data
    : axiosResponse.data?.data;
  const message =
    axiosTimeout.message ||
    (axiosError.isAxiosError
      ? axiosError.response?.data?.message
      : axiosResponse.data?.message);
  return {
    url,
    status,
    headers,
    param,
    code,
    data,
    message,
  };
};
/**
 * Axios request log, sync to slardar
 * @param res
 */
export function axiosResponseToSlardar(
  res:
    | AxiosResponse<IFeelgoodResponse>
    | AxiosError<IFeelgoodResponse>
    | IAxiosTimeout,
) {
  try {
    const { config } = res;
    const method = config.method as string;
    const { url, status, headers, param, code, data, message } =
      getAxiosInfo(res);
    let level: TLevel = 'error';
    if (status === '0') {
      level = 'debug';
    } else if (status === '200') {
      level = code === '0' ? 'info' : 'warn';
    }
    let host = '';
    let pathname = url;
    try {
      const Url = new URL(url);
      // eslint-disable-next-line prefer-destructuring
      host = Url.host;
      // eslint-disable-next-line prefer-destructuring
      pathname = Url.pathname;
    } catch {}
    const tag = {
      level,
      method,
      url,
      host,
      pathname,
      status,
      'x-tt-logid': headers?.['x-tt-logid'],
      'x-feelgood-cluster': headers?.['x-feelgood-cluster'],
      param,
      code,
      data: JSON.stringify(data),
      message: message || '',
      href: window?.location?.href || 'no href',
    };
    sendSlardarEvent('ajax', {
      categories: tag,
    });
  } catch (error: any) {
    const cache: any[] = [];
    const level = 'error';
    const tag = {
      code: 'failed_to_foramt',
      error: error?.message || JSON.stringify(error),
      response: JSON.stringify(res, (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.includes(value)) {
            // 移除
            return undefined;
          }
          // 收集所有的值
          cache.push(value);
        }
        return value;
      }),
    };
    sendSlardarEvent('ajax', {
      categories: {
        level,
        ...tag,
      },
    });
  }
}
